/**
 * Abstract service
 */
class AbstractService {

    /**
     * Request call.
     * 
     * @param {*} requestURL 
     * @param {*} method 
     * @param {*} headers 
     * @param {*} postData 
     * @param {*} async 
     * @returns 
     */
    sendRequest = (requestURL, method = "GET", headers = {}, postData) => {
        let options = null;
        
        switch (method) {
            case "POST_POWER_BI":
                method = "POST";
                options = {
                    method,
                    headers,
                    body: postData
                }
                break;
            case "GET_POWER_BI":
                method = "GET";
                options = {
                    method,
                    headers
                }
                break;
            case "POST":
                options = {
                    method,
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'include',
                    headers,
                    body: JSON.stringify(postData)
                }
                break;
            case "GET":
                options = {
                    method,
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'include',
                }
                break;
            case "PATCH":
                options = {
                    method,
                    headers,
                    body: postData
                }
                break;
            default:
                break;
        }
    
        return fetch(requestURL, options);
    }
}

export default AbstractService;