export const instagramResponse = [
  {
    id: 0,
    src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
    width: 4,
    height: 3,
    caption:
      "Rubik is a React application which integrates the Powerbi and angular reports into a single application. The MSTR embedded API is used to add the PBI and MSTR reports into the application, on top of that we have implemented some features",
  },
  {
    id: 1,
    src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
    width: 1,
    height: 1,
    caption:
      "Rubik is a React application which integrates the Powerbi and angular reports into a single application. The MSTR embedded API is used to add the PBI and MSTR reports into the application, on top of that we have implemented some features",
  },
  {
    id: 2,
    src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
    width: 3,
    height: 4,
    caption:
      "Rubik is a React application which integrates the Powerbi and angular reports into a single application. The MSTR embedded API is used to add the PBI and MSTR reports into the application, on top of that we have implemented some features",
  },
  {
    id: 3,
    src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
    width: 3,
    height: 4,
    caption:
      "Rubik is a React application which integrates the Powerbi and angular reports into a single application. The MSTR embedded API is used to add the PBI and MSTR reports into the application, on top of that we have implemented some features",
  },
  {
    id: 4,
    src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
    width: 3,
    height: 4,
    caption:
      "Rubik is a React application which integrates the Powerbi and angular reports into a single application. The MSTR embedded API is used to add the PBI and MSTR reports into the application, on top of that we have implemented some features",
  },
  {
    id: 5,
    src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
    width: 4,
    height: 3,
    caption:
      "Rubik is a React application which integrates the Powerbi and angular reports into a single application. The MSTR embedded API is used to add the PBI and MSTR reports into the application, on top of that we have implemented some features",
  },
  {
    id: 6,
    src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
    width: 3,
    height: 4,
    caption:
      "Rubik is a React application which integrates the Powerbi and angular reports into a single application. The MSTR embedded API is used to add the PBI and MSTR reports into the application, on top of that we have implemented some features",
  },
  {
    id: 7,
    src: "https://source.unsplash.com/PpOHJezOalU/800x599",
    width: 4,
    height: 3,
    caption:
      "Rubik is a React application which integrates the Powerbi and angular reports into a single application. The MSTR embedded API is used to add the PBI and MSTR reports into the application, on top of that we have implemented some features",
  },
  {
    id: 8,
    src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
    width: 4,
    height: 3,
    caption:
      "Rubik is a React application which integrates the Powerbi and angular reports into a single application. The MSTR embedded API is used to add the PBI and MSTR reports into the application, on top of that we have implemented some features",
  },
];
