import React, { Component } from 'react'
import './Topbar.css';
import { connect } from 'react-redux';

import Logo from "./../../UI/Logo";
import MenuIcon from '@material-ui/icons/Menu';
import { Openbar } from './../Openbar/Openbar'

class Topbar extends Component {

    /**
     * Constructor.
     * 
     * @param {*} props 
     */
     constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    /**
     * Open left panel handler
     */
    toggleDrawer = () => {

        this.setState({
            open: !this.state.open
        })
    };

    render = () => {
      
        let isOpen = this.state.open;
      
        if(!isOpen)
            return (
                <div id="topbar" >
                    <div className={"mobile-topbar"}>
                        <div className={"mobile-topbar-left"} >
                                {/* App logo for left panel menu */}
                                <Logo size={"40"} />
                        </div>
                        <div className={"mobile-appTitle"}>{this.props.config.appName}</div>
                        <div className={"mobile-topbar-right"} >
                            <MenuIcon onClick={this.toggleDrawer} className="sideBarIcon" />
                        </div>
                    </div>
                </div>
            );

        return (
                <div id="topbar">
                    <Openbar parent={this} config={this.props.config} />
                </div>
        )

    
    }

}

const mapStateToProps = (state) => {
    
    return {
        config: state.config
    }
}



export default connect(mapStateToProps, null)(Topbar);