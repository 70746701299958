const useStyles = (theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #bf2e1a',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      zIndex: 9999
    },
    title : {
        borderBottom: '1px solid red'
    }
  });

  export default useStyles;
  