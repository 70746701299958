const CustomError = (error) => {
    switch (error.errorCode) {
        case "ERR017":
            const errorMessage = error.message;
            const errorRegex = "(?<=Definition object)(.*)(?=in Project)";
            const objectIdRegex = errorMessage.match(errorRegex);
            let objectId = "";
            if (objectIdRegex && objectIdRegex.length) {
                objectId = objectIdRegex[0];
            }

            return {
                errorTitle: "Access issue",
                errorMessage: "Looks like you don’t have access to the Report " + objectId + " If you need to access this report, please raise a request on Spirit portal"
            };

        case "ERR004":
            return {
                errorTitle: "Whoops, your session has expired",
                errorMessage: "No worry, simply hit refresh or restart the app to view your reports again."
            };

        default:
            // Check for statusCode
            if (error.statusCode === 504) {
                return {
                    errorTitle: "It’s taking longer than usual…",
                    errorMessage: "Oops, this report seems to be taking longer than usual to load. Please refresh the page to reload the report or reach out to analytics@dfs.com if the issue persists."
                };
            } else if (error.desc === "One or more datasets are not loaded for this item.") {
                return {
                    errorTitle: "Oops, the dataset is not loading.",
                    errorMessage: "One of the datasets is not loaded for this report, please try to restart the app to view the report again."
                };
            } else {
                return null;
            }
    }
};

export default CustomError;
