
const drawerWidth = 240;
const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    treeIcon: {
        marginRight: "8px"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: "56px",
        [theme.breakpoints.up('sm')]: {
            width: "56px",
        },
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        position:'relative'
    },
    container: {
        maxWidth: "100%",
        maxHeight: "100%",
        height: "100%"
    },
    paper: {

        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: "100%"
    },

    menuButton: {
        margin: "0 auto",
        width: "100%"
    },
    menuButtonHidden: {
        display: 'none',
    },
    menuGridspacing: {
        width: "100%",
        height: "100% !important"
    },
    firstGridSpacing: {
        height: "100% !important"
    }

});

export default useStyles;