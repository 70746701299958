class DeviceHelper {
    isMobile = () => {
        return (/Android|webOS|iPhone|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini,/i.test(navigator.userAgent));
    }

    isTablet = () =>{
        return (this.isIOS() && 'ontouchstart' in document.documentElement)
    }

    isIOS() {
        if (/iPad|iPod|iPod Simulator|iPad Simulator|Macintosh|MacIntel,/i.test(navigator.userAgent)) {
          return true;
        } else {
          return navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 2 &&
            /MacIntel/.test(navigator.userAgent);
        }
    }

    getOrientation = () => {
        const models = window['powerbi-client'].models;

        //desktop browsers
        if (window.screen && window.screen.orientation && window.screen.orientation.type) {
            if (window.screen.orientation.type.includes("portrait")){
                return models.LayoutType.MobilePortrait; 
            }
            else return models.LayoutType.Master;
        }
        //mobile browsers
        else if (window.orientation){
            if (window.orientation === 0 || window.orientation === 180){
                return models.LayoutType.MobilePortrait;
            }
            else return models.LayoutType.Master;
        }
        //default
        else return models.LayoutType.Master;
    }
}

export const deviceHelper = new DeviceHelper();