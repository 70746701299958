import { BugReport, Info, LiveHelp } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import { Component } from "react";
import IconLink from "./IconLink";

const useStyles = (theme) => ({
    buttonBar: {
        bottom: 0,
        background: '#161616',
        width: 'inherit',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        display: 'flex'
    },
    buttonBarHorizontal: {
        flexDirection: 'row'
    },
    buttonBarVertical: {
        flexDirection: 'column'
    }
});

class BottomButtons extends Component {

    render = () => {
        const { classes } = this.props;

        return <div className={`${classes.buttonBar} ${this.props.open ? classes.buttonBarHorizontal : classes.buttonBarVertical}`}>
            <IconLink title={"Ask a question"} href={this.props.config.askQuestionURL}><LiveHelp /></IconLink>
            <IconLink title={"Log an issue"} href={this.props.config.reportIssueURL}><BugReport /></IconLink>
            <IconLink title={"Learn more"} href={this.props.config.learnMoreURL}><Info /></IconLink>
        </div>
    }
}

export default withStyles(useStyles)(BottomButtons);