import initialState from "./initialState";
import { EMBED_OBJECT_TYPES } from "./utils/Constants";
import { getDefaultPage } from "./utils/stateMethods";
import { deviceHelper } from "./utils/DeviceHelper";

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOAD_CONFIG:
      const config = action.payload;
      return {
        ...state,
        config: config,
        enablePowerBI: config.enablePowerBI,
      };
    case ACTIONS.SET_AUTH_TOKEN:
      return {
        ...state,
        mstrAuthToken: action.payload.authToken,
        identityToken: action.payload.identityToken,
        user: action.payload.user,
      };
    case ACTIONS.SET_DOSSIER_ID:
      const dossierId = action.payload;
      return {
        ...state,
        selectedObject: {
          type: EMBED_OBJECT_TYPES.MSTR_DOSSIER,
          projectId: state.config.projectID,
          objectId: dossierId,
          pageName: null,
          embedUrl: null,
        },
      };
    case ACTIONS.SET_DOSSIER_NAME:
      const dossierName = action.payload;
      return {
        ...state,
        selectedObject: {
          ...state.selectedObject,
          reportName: dossierName
    
        },
      };
    case ACTIONS.SET_AUTHENTICATED:
      return {
        ...state,
        mstrAuthToken: action.payload.authToken,
      };
    case ACTIONS.SET_TREE_STRUCTURE:
      return {
        ...state,
        folderTree: action.payload.folderTree,
        immutableFolderTree: action.payload.folderTree,
        favoriteObjects: action.payload.favoriteObjects,
        userId: action.payload.userId,
        selectedNodes: action.payload.selectedNodes,
        expandedNodes: action.payload.expandedNodes,
      };
    case ACTIONS.DISPLAY_MESSAGE:
      return {
        ...state,
        error: action.payload.error,
      };
    case ACTIONS.SET_DEFAULT_EXPANDED_NODES:
      return {
        ...state,
        expandedNodes: action.payload.expandedNodes,
      };
    case ACTIONS.SET_TREE_NODE_SELECTED:
      return {
        ...state,
        selectedNodes: action.payload.selectedNodes,
      };
    case ACTIONS.SET_POWERBI_REPORT_DATA:
      const report = action.payload;

      const objectId = report.objectId;
      const workspaceId = report.workspaceId;
      const objectType = report.objectType;
      const pageName = report.pageName
        ? report.pageName
        : getDefaultPage(state, report.objectId);
      const embedURL = report.embedURL;
      const applyRoles = report.applyRoles;
      const reportName = report.reportName;
      const alertMessage = report.alertMessage
      const isFilter = report.isFilter

      return {
        ...state,
        selectedObject: {
          type: objectType,
          projectId: workspaceId,
          objectId: objectId,
          pageName: pageName,
          embedUrl: embedURL,
          applyRoles: applyRoles,
          reportName: reportName,
          alertMessage: alertMessage,
          isFilter: isFilter
        },
        selectedObjectType: objectType,
        embeddedDossierStatus: null,
      };
    case ACTIONS.SET_POWERBI_TOKEN:
      const powerBIToken = action.payload.powerBIToken;
      return {
        ...state,
        powerBIToken: powerBIToken,
        enablePowerBI: !!powerBIToken,
      };
    case ACTIONS.SET_IDENTITY_TOKEN:
      const identityToken = action.payload;

      return {
        ...state,
        identityToken: identityToken,
      };
    case ACTIONS.SET_SELECTED_BUTTON:
      const selectButtonTree = action.payload.folderTree;
      const selectedButton = action.payload.selectedButton;
      return {
        ...state,
        selectedButton: selectedButton,
        folderTree: selectButtonTree,
      };
    case ACTIONS.SET_FAVORITE_OBJECTS:
      const isFavorite = action.payload.isFavorite;
      return {
        ...state,
        isFavorite: isFavorite,
      };
    case ACTIONS.SET_FLAT_TREE_STRUCTURE:
      const flatFolderTree = action.payload.flatFolderTree;
      return {
        ...state,
        flatFolderTree,
      };
    case ACTIONS.TURN_OFF_POWERBI:
      return {
        ...state,
        enablePowerBI: false,
      };
    case ACTIONS.OPEN_HOME_PAGE:
      //Rubik-712
      const isMobile = deviceHelper.isMobile();
      const isTablet = deviceHelper.isTablet();
      let dataWWdsf = "";
      let dataStorePer = "";
      if (state.userInfoDetails) {
        if (state.userInfoDetails["memberships"]) {
          dataWWdsf = state.userInfoDetails["memberships"].filter((data) => {
            return state.config["wwdfsLandingAccess"].includes(data["name"]);
          });
          dataStorePer = state.userInfoDetails["memberships"].filter((data) => {
            return state.config["storePerformanceAccess"].includes(
              data["name"]
            );
          });
        }
      }
      dataWWdsf = dataWWdsf.length ? true : false;
      dataStorePer = dataStorePer.length ? true : false;

      let DossierData = {};
      let bothGroup = dataWWdsf == dataStorePer ? true : false;
      if (bothGroup) {
        console.log("both home");
        if (isTablet) {
          DossierData = {
            defaultDossierProjectID:
              state.config.defaultLandingIpadDossierProjectID,
            defaultDossierID: state.config.defaultLandingIpadDossierID,
            defaultPageKey: state.config.defaultLandingIpadPageKey,
          };
        } else if (isMobile) {
          DossierData = {
            defaultDossierProjectID:
              state.config.defaultLandingMobileDossierProjectID,
            defaultDossierID: state.config.defaultLandingMobileDossierID,
            defaultPageKey: state.config.defaultLandingMobilePageKey,
          };
        } else {
          DossierData = {
            defaultDossierProjectID:
              state.config.defaultLandingDossierProjectID,
            defaultDossierID: state.config.defaultLandingDossierID,
            defaultPageKey: state.config.defaultLandingPageKey,
          };
        }
      } else if (dataWWdsf) {
        console.log("wwdsf home");
        if (isTablet) {
          DossierData = {
            defaultDossierProjectID: state.config.defaultIpadDossierProjectID,
            defaultDossierID: state.config.defaultIpadDossierID,
            defaultPageKey: state.config.defaultIpadPageKey,
          };
        } else if (isMobile) {
          DossierData = {
            defaultDossierProjectID: state.config.defaultMobileDossierProjectID,
            defaultDossierID: state.config.defaultMobileDossierID,
            defaultPageKey: state.config.defaultMobilePageKey,
          };
        } else {
          DossierData = {
            defaultDossierProjectID: state.config.defaultDossierProjectID,
            defaultDossierID: state.config.defaultDossierID,
            defaultPageKey: state.config.defaultPageKey,
          };
        }
      } else if (dataStorePer) {
        console.log("store home");
        if (isTablet) {
          DossierData = {
            defaultDossierProjectID:
              state.config.defaultLandingIpadDossierProjectID,
            defaultDossierID: state.config.defaultLandingIpadDossierID,
            defaultPageKey: state.config.defaultLandingIpadPageKey,
          };
        } else if (isMobile) {
          DossierData = {
            defaultDossierProjectID:
              state.config.defaultLandingMobileDossierProjectID,
            defaultDossierID: state.config.defaultLandingMobileDossierID,
            defaultPageKey: state.config.defaultLandingMobilePageKey,
          };
        } else {
          DossierData = {
            defaultDossierProjectID:
              state.config.defaultLandingDossierProjectID,
            defaultDossierID: state.config.defaultLandingDossierID,
            defaultPageKey: state.config.defaultLandingPageKey,
          };
        }
      }

      return {
        ...state,
        selectedObject: {
          type: EMBED_OBJECT_TYPES.MSTR_DOSSIER,
          projectId: DossierData.defaultDossierProjectID,
          objectId: DossierData.defaultDossierID,
          pageName: DossierData.defaultPageKey,
          embedUrl: null,
        },
        selectedNodes: [],
      };
    case ACTIONS.SET_DOSSIER:
      const dossier = action.payload;

      const pageKey = dossier.pageKey
        ? dossier.pageKey
        : getDefaultPage(state, dossier.id);

      return {
        ...state,
        selectedObject: {
          type: EMBED_OBJECT_TYPES.MSTR_DOSSIER,
          projectId: dossier.projectId,
          objectId: dossier.id,
          pageName: pageKey,
          embedUrl: null,
          pageMenu: dossier.pageMenu,
          reportName: dossier.reportName,
          alertMessage: dossier.alertText,
          isFilter:dossier.isFilter
          // favoriteId: dossier.favoriteId
        },
        embeddedDossierStatus: null,
      };
    case ACTIONS.SET_EMBEDDED_DOSSIER_STATUS:
      return {
        ...state,
        embeddedDossierStatus: action.payload,
        error: {
          code: null,
          title: null,
          message: null,
        },
      };
    case ACTIONS.SHOW_PROGRESSBAR:
      return {
        ...state,
        progressBar: true,
      };
    case ACTIONS.HIDE_PROGRESSBAR:
      return {
        ...state,
        progressBar: false,
      };
    case ACTIONS.SET_USER_DETAILS:
      return {
        ...state,
        userInfoDetails: action.payload,
      };
    case ACTIONS.SET_ALERT_STATUS:
      const alertStatus = action.payload;
      return {
        alert: alertStatus,
      };
    default:
      return state;
  }
};

export default rootReducer;

export const ACTIONS = {
  LOAD_CONFIG: "LOAD_CONFIG",
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
  SET_DOSSIER_ID: "SET_DOSSIER_ID",
  SET_AUTHENTICATED: "SET_AUTHENTICATED",
  SET_TREE_STRUCTURE: "SET_TREE_STRUCTURE",
  SET_DASHBOARD_LOADED: "SET_DASHBOARD_LOADED",
  DISPLAY_MESSAGE: "DISPLAY_MESSAGE",
  SET_DEFAULT_EXPANDED_NODES: "SET_DEFAULT_EXPANDED_NODES",
  SET_TREE_NODE_SELECTED: "SET_TREE_NODE_SELECTED",
  SET_POWERBI_REPORT_DATA: "SET_POWERBI_REPORT_DATA",
  SET_POWERBI_TOKEN: "SET_POWERBI_TOKEN",
  SET_IDENTITY_TOKEN: "SET_IDENTITY_TOKEN",
  SET_SELECTED_BUTTON: "SET_SELECTED_BUTTON",
  SET_FAVORITE_OBJECTS: "SET_FAVORITE_OBJECTS",
  SET_FLAT_TREE_STRUCTURE: "SET_FLAT_TREE_STRUCTURE",
  TURN_OFF_POWERBI: "TURN_OFF_POWERBI",
  OPEN_HOME_PAGE: "OPEN_HOME_PAGE",
  SET_DOSSIER: "SET_DOSSIER",
  SET_EMBEDDED_DOSSIER_STATUS: "SET_EMBEDDED_DOSSIER_STATUS",
  SHOW_PROGRESSBAR: "SHOW_PROGRESSBAR",
  HIDE_PROGRESSBAR: "HIDE_PROGRESSBAR",
  SET_USER_DETAILS: "SET_USER_DETAILS",
  SET_ALERT_STATUS: "SET_ALERT_STATUS",
  SET_DOSSIER_NAME: "SET_DOSSIER_NAME",
};
