import AbstractService from "./AbstractService";

/**
 * DossierService. Uses MicroStrategy embedded API to embedded dossier into the application
 */
class DossierService extends AbstractService {
  /**
   * Create dossier
   * @param {*} dossierID
   */
  createDossierInstance = (dossierID, authToken, config) => {
    let APIPath = `/api/dossiers/${dossierID}/instances`;
    let completeRestURL = config.mstrLibraryUrl + APIPath;
    //send server request
    return this.sendRequest(
      completeRestURL,
      "POST",
      {
        "Content-type": "application/json",
        accept: "application/json",
        "X-MSTR-AuthToken": authToken,
        "X-MSTR-ProjectID": config.projectID,
      },
      {
        persistViewState: true,
      }
    );
  };

  promptsDocument = async (config, authToken, projectID, dossierID) => {
    const promptURL = `${config.mstrLibraryUrl}/api/documents/${dossierID}/prompts`;

    const headers = {
      accept: "application/json",
      "X-MSTR-AuthToken": authToken,
      "X-MSTR-ProjectID": projectID,
    };

    const options = {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers,
    };

    const res = await fetch(promptURL, options);

    if (res.ok) {
      const json = await res.json();
      return json;
    } 
  };
  

  publishDocument = async (config, authToken, projectID, dossierID, userID) => {
    const PublishURL = `${config.mstrLibraryUrl}/api/library`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-MSTR-AuthToken": authToken,
        "X-MSTR-ProjectID": projectID,
      },
      body: JSON.stringify({
        id: dossierID,
        recipients: [
          {
            id: userID,
          },
        ],
        isInstance: false,
      }),
    };
    fetch(PublishURL, requestOptions).then((response) => {
      console.log("Response ===> ", response);
    });
  };

  keySearchAPI = async (config, inputValue) => {
    const url = `${config.mstrLibraryUrl}/servlet/taskProc?taskId=searchKeyStoreTask&taskEnv=xml&taskContentType=xml&inputValue=${inputValue}`;

    return this.sendRequest(url, "GET", {
        'Content-Type': 'application/json',
        'accept':"application/json",
        'Access-Control-Allow-Origin':'*'
    }, {});
}

  getStatus = async (projectId, dossierId, instanceId, config, authToken) => {
    const url = `${config.mstrLibraryUrl}/api/documents/${dossierId}/instances/${instanceId}/status`;

    const headers = {
      accept: "application/json",
      "X-MSTR-AuthToken": authToken,
      "X-MSTR-ProjectID": projectId,
    };

    const options = {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers,
    };

    const res = await fetch(url, options);

    if (res.ok) {
      const json = await res.json();
      return json.status;
    } else return 0;
  };
}

export const dossierService = new DossierService();
