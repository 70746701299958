import { Tooltip } from '@material-ui/core'
import { Component } from 'react'

class DynamicTooltip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    render() {
        return <Tooltip placement="top" open={this.state.open} title="Share link copied to clipboard">{this.props.children}</Tooltip>
    }

    open = () => {
        this.setState({
            open: true
        });
        setTimeout(this.close, 3000);
    }

    close = () => {
        this.setState({
            open: false
        });
    }
}

export default DynamicTooltip;