import React, { Component } from 'react';
import useStyles from './DashboardStyle'
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import EmbeddedDossier from '../Frame/EmbeddedDossier';
import Menu from '../Menu/Menu';
import Topbar from '../Mobile/Topbar/Topbar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Progress from '../Dashboard/Progress';
import { deviceHelper } from '../../utils/DeviceHelper';

/**
 * Dashboard component is entire application component that holds dynamic panel and 
 * Embeedded MicroStrategy Dossiers.
 */
class Dashboard extends Component {

  constructor(props){
    super(props);

    this.embeddedMSTRDossier = null;
    this.embeddedPBIReport = null;
  }

  render = () => {
    const { classes } = this.props;
    let fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
      <div className={classes.root} id="menuContainer">
        <CssBaseline />
        <Topbar  />
        {/* Left menu component */}
        <Menu classes={classes} onShare={this.onShare}/>
        {/* Main dashboard cointainer */}
        <main className={classes.content} id="mainDashboard">
        {this.props.progressBar && <Progress/>}
          <Container className={classes.container} id="mainDashboardContainer">
            <Grid container  className={classes.firstGridSpacing}>
              <Grid item className={classes.menuGridspacing}>
                <Paper className={fixedHeightPaper}>
                  {/* Embedded MicroStrategy dossier via embedded API*/}
                  <EmbeddedDossier onEmbedPBIReport={this.onEmbedPBIReport} onEmbedMSTRDossier={this.onEmbedMSTRDossier} />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
    );
  }

  onEmbedMSTRDossier = (dossier) => {
    this.embeddedPBIReport = null;
    this.embeddedMSTRDossier = dossier;
  }

  onEmbedPBIReport = (report) => {
    this.embeddedPBIReport = report;
    this.embeddedMSTRDossier = null;
  }

  onShare = async (shareUrl, objectId, objectType) => {
    const dossier = this.embeddedMSTRDossier;
    const pbiReport = this.embeddedPBIReport;

    //the logic below is activated if we share a dossier/report which is currently displayed - in such case we need to include page name
    if (dossier){
      const dossierId = await dossier.getDossierId();

      if (objectType === "MSTR" && objectId === dossierId){
        const page = dossier.getCurrentPage();
        shareUrl += `/${page.nodeKey}`;
      }
    }
    else if (pbiReport){
      const reportId = pbiReport.getId();

      if (objectType === "PBI" && objectId === reportId){
        const pages = await pbiReport.getPages();

        const currentPage = pages.filter(page => page.isActive)[0];

        shareUrl += `/${currentPage.name}`;
      }
    }
    const isMobile = deviceHelper.isMobile();
    shareUrl += isMobile ? "#mobile" : "#desktop"

    return navigator.clipboard.writeText(shareUrl);
  }
}

function mapStateToProps (state) {
  return {
    selectedObject: state.selectedObject,
    progressBar: state.progressBar
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  }, dispatch);
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Dashboard));