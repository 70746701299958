import React, { Component } from "react";
import { connect } from "react-redux";

class AlertMobile extends Component {
  constructor(props) {
    super(props);
  
  }

  render() {
    return (
      <>
        {this.props.config.alertState[1] && (
          <div className="alert" style={{ backgroundColor: `${this.props.config.alertText[1].bgcolor}`}}>
            <span className="alert-text" style={{ color: `${this.props.config.alertText[1].color}`}}>{this.props.config.alertText[1].alertMessage}</span>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

export default connect(mapStateToProps)(AlertMobile);
