import React, { createRef } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Component } from 'react'
import MultiTreeView from './MultiTreeView'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './MultiSelectTreeView.css'
import { setExpandedNodes, setSelectedNodes, setSelectedPowerBIObjectData, setSelectedDossier } from '../../../../actions'
import { Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DynamicTooltip from './DynamicTooltip';



const initialState = {
  contextX: 0,
  contextY: 0,
  selectedNode: null
}

const useStyles = (theme) => ({
  contextMenu: {
    '& .MuiPaper-root': {
      backgroundColor: 'rgb(42, 42, 42)',
      border: 'rgb(95, 106, 119) 2px solid',
      width: '200px'
    }
  },
  contextMenuItem: {
    fontSize: '14px',
    color: 'rgb(149, 149, 149) !important',
    textDecoration: 'none'
  }
});

/**
 * Multi select tree view.
 * 
 * @returns 
 */
class MultiSelectTreeView extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;

    this.tooltip = createRef();
  }

  render = () => {

    const { classes } = this.props;

    const selectedNode = this.state.selectedNode;

    const coinId = this.getCoinId(selectedNode);
    const coinLink = <a className={classes.contextMenuItem} href={this.props.coinBaseURL + coinId} target='blank'>More information</a>;

    return <div className="treeView">
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect={false}
        expanded={[...this.props.expandedNodes]}
        selected={[...this.props.selectedNodes]}
        onNodeToggle={this.onNodeToggle}
        onNodeSelect={this.onNodeSelect}
        className="tree"
      >
        {
          //render tree view
          <MultiTreeView
            nodes={this.props.folderTree}
            onContextMenu={this.openContextMenu}
          />
        }
      </TreeView>
      <Menu
        className={classes.contextMenu}
        keepMounted
        open={!!(this.state.contextX && this.state.contextY)}
        onClose={this.closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: this.state.contextY,
          left: this.state.contextX
        }}
        onContextMenu={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          this.closeContextMenu();
        }}
      >
        {coinId && <MenuItem onClick={this.closeContextMenu} className={classes.contextMenuItem}>{coinLink}</MenuItem>}
        {(selectedNode && (selectedNode.did || selectedNode.powerBIId)) && <DynamicTooltip ref={this.tooltip}><MenuItem onClick={() => { this.share(selectedNode) }} className={classes.contextMenuItem}>Share</MenuItem></DynamicTooltip>}
        <MenuItem onClick={() => { this.shareEmail(selectedNode) }} className={classes.contextMenuItem}>Share Via Email</MenuItem>
      </Menu>
    </div>
  }

  getCoinId = (selectedNode) => {
    if (selectedNode) {
      return selectedNode.longDescriptionProps ? selectedNode.longDescriptionProps.coinId : null;
    }
    else return null;
  }

  shareEmail = (selectedNode) => {
    let url = "";
    let subject = "";
    let mobileURL = "";
    let shareURL = "";
    if (selectedNode.longDescriptionProps) {    
      if (selectedNode.longDescriptionProps["workspaceId"]) {
        url = `${window.location.origin}/rubik/embed/pbi/${selectedNode.longDescriptionProps["workspaceId"]}/${selectedNode.longDescriptionProps["type"]}s/${selectedNode.longDescriptionProps["objectId"]}`
        mobileURL = "https://rubik.dfs.com/rubik/RubikApp.html?reportType=" + "pbi" + "%26projectID=" + selectedNode.longDescriptionProps["workspaceId"] + "%26reportID=" + selectedNode.longDescriptionProps["mobileEmailShare"]
        // mobileURL = "Rubik://rubik/" + "pbi" + "?" + selectedNode.longDescriptionProps["workspaceId"] + "%26" + selectedNode.longDescriptionProps["mobileEmailShare"]
      } else if (selectedNode.longDescriptionProps["paprojectid"]) {
        url = `${window.location.origin}/rubik/embed/mstr/${selectedNode.longDescriptionProps["paprojectid"]}/${selectedNode.longDescriptionProps["objectId"]}`
        mobileURL = "https://rubik.dfs.com/rubik/RubikApp.html?reportType=" + "mstr" + "%26projectID=" + selectedNode.longDescriptionProps["paprojectid"] + "%26reportID=" + selectedNode.longDescriptionProps["mobileEmailShare"]
        // mobileURL = "Rubik://rubik/" + "mstr" + "?" + selectedNode.longDescriptionProps["paprojectid"] + "%26" + selectedNode.longDescriptionProps["mobileEmailShare"]
      } else {
        url = `${window.location.origin}/rubik/embed/mstr/${this.props.projectId}/${selectedNode.longDescriptionProps["objectId"]}`
        mobileURL = "https://rubik.dfs.com/rubik/RubikApp.html?reportType=" + "mstr" + "%26projectID=" + this.props.projectId + "%26reportID=" + selectedNode.longDescriptionProps["mobileEmailShare"]
      }

      subject = encodeURIComponent(selectedNode.longDescriptionProps["displayName"])
    }
    shareURL = "mailto:?subject=" + subject + "&body=Hi,%0D%0A%0D%0ACheck the below links for " + subject + ":%0D%0A%0D%0A"

    if(selectedNode.longDescriptionProps["mobileEmailShare"]){
      shareURL += `Mobile:%0D%0A${mobileURL}%0D%0A%0D%0A`
    }
    
    //    finalUrl = `https://outlook.office.com/mail/deeplink/compose?mailtouri=mailto:?subject=${subject}%26body=Hi,%0D%0A%0D%0ACheck the below links for ${selectedNode.longDescriptionProps["displayName"]}:%0D%0A%0D%0AMobile:%0D%0Ahttps://insightbiqa.dfs.com/rubik/RubikApp.html%0D%0A%0D%0ADesktop:%0D%0A${url}`
    window.open(shareURL + `Desktop:%0D%0A${url}`, "_blank")
  }

  share = (selectedNode) => {
    let shareUrl = "";
    let objectId = null;
    let objectType = null;

    if (selectedNode.did) {
      //MSTR dossier URL
      //Rubik-700 Issues with Share function
      if (selectedNode.longDescriptionProps["paprojectid"]) {
        shareUrl = `${window.location.origin}/rubik/embed/mstr/${selectedNode.longDescriptionProps["paprojectid"]}/${selectedNode.did}`;
      } else {
        shareUrl = `${window.location.origin}/rubik/embed/mstr/${this.props.projectId}/${selectedNode.did}`;
      }
      objectId = selectedNode.did;
      objectType = "MSTR";
    }
    else if (selectedNode.powerBIId) {
      //PBI workflow
      //Rubik-700 Issues with Share function
      if (selectedNode.longDescriptionProps) {
        if (selectedNode.longDescriptionProps["applyRoles"]) {
          shareUrl = `${window.location.origin}/rubik/embed/pbi/${selectedNode.powerBiWorkspaceId}/${selectedNode.powerBIType}s/${selectedNode.powerBIId}/roles/${selectedNode.longDescriptionProps["applyRoles"]}`;
        } else {
          shareUrl = `${window.location.origin}/rubik/embed/pbi/${selectedNode.powerBiWorkspaceId}/${selectedNode.powerBIType}s/${selectedNode.powerBIId}`;
        }
      }
      objectId = selectedNode.powerBIId;
      objectType = "PBI";
    }

    this.props.onShare(shareUrl, objectId, objectType)
      .then(() => {
        this.tooltip.current && this.tooltip.current.open();
      });
  }

  openContextMenu = (evt, id) => {
    const selectedNode = this.getNodeByID(this.props.flatFolderTree, parseInt(id));

    if (selectedNode && this.isContextMenuAvailable(selectedNode)) {
      this.setState({
        contextX: evt.clientX,
        contextY: evt.clientY,
        selectedNode: selectedNode
      });
    }
  }

  isContextMenuAvailable = (selectedNode) => {
    const coinId = this.getCoinId(selectedNode);

    return coinId || selectedNode.did || selectedNode.powerBIId;
  }

  closeContextMenu = () => {
    //need to keep coinId set to keep the hyperlink valid
    this.setState({
      contextX: 0,
      contextY: 0,
      selectedNode: null
    });
  }

  /**
 * Event handler on node expand
 */
  onNodeToggle = (event, nodeIds) => {
    const tagName = event.target.tagName;
    if (tagName === "path" || tagName === "svg") {
      event.expanded = true;
    }
    this.props.setExpandedNodes(nodeIds);
  }

  /**
   * Event handler on select node.
   * 
   * @param {*} event 
   * @param {*} value 
   */
  onNodeSelect = (event, value) => {
    if (event.expanded) return;

    let toBeHidden = false;
    let parentElement = event.currentTarget.parentElement;

    if (parentElement.attributes && parentElement.attributes["pbworkspaceid"]) {
      toBeHidden = true;
      let powerBIObjectId = parentElement.attributes["id"].textContent;
      let workspaceId = parentElement.attributes["pbworkspaceid"].textContent;
      let objectType = parentElement.attributes["pbobjecttype"].textContent;
      let reportName = parentElement.attributes["title"].textContent;
      let alertMessage = ""
      let isFilter = ""
      if (parentElement.attributes && parentElement.attributes["alertMessage"]) {
        alertMessage = parentElement.attributes["alertMessage"].textContent
      }
      if (parentElement.attributes && parentElement.attributes["isFilter"]) {
        isFilter = parentElement.attributes["isFilter"].textContent
      }

      //Rubik-718
      this.props.setSelectedPowerBIObjectData(workspaceId, powerBIObjectId, objectType, reportName, "", "", alertMessage,isFilter);
    } else if (parentElement.id) {
      toBeHidden = true;
      //Rubik-640
      let attrProjectId = "";
      let reportName = parentElement.attributes["title"].textContent;
      // let favoriteId = reportName
      // if(parentElement.attributes && parentElement.attributes["favoriteId"]){
      //   favoriteId = parentElement.attributes["favoriteId"].textContent;
      //  }

      if (parentElement.attributes && parentElement.attributes["paprojectid"]) {
        attrProjectId = parentElement.attributes["paprojectid"].textContent;
      } else {
        attrProjectId = this.props.projectId;
      }

      let pageMenuFlag = false
      if (parentElement.attributes && parentElement.attributes["pageMenu"]) {
        if (parentElement.attributes["pageMenu"].textContent === "true") {
          pageMenuFlag = true
        }
      }

      let alertMessage = ""
      if (parentElement.attributes && parentElement.attributes["alertMessage"]) {
        alertMessage = parentElement.attributes["alertMessage"].textContent
      }

      let isFilter = ""
      if (parentElement.attributes && parentElement.attributes["isFilter"]) {
        isFilter = parentElement.attributes["isFilter"].textContent
      }


      const dossier = {
        projectId: attrProjectId,
        id: parentElement.id,
        pageKey: undefined,
        pageMenu: pageMenuFlag,
        reportName: reportName,
        alertText: alertMessage,
        isFilter:isFilter
      };

      this.props.setSelectedDossier(dossier);
    }

    /**
     * If parent exists, this is mobile flow. We need to hide the navigation bar.
     */
    if (toBeHidden && this.props.parent) {
      this.props.parent.hideBar();
    }

    if (value) {
      const type = parentElement.getAttribute("type");


      const expandedNodes = this.getExpandedNodes(value, parseInt(type))
      this.populateExpandedNodes(expandedNodes);

      const selectedNodes = this.getSelectedNodes(value, type);
      //select all node along with parents
      this.props.setSelectedNodes(selectedNodes);
    }
  }


  /**
   * Get parents node id for selected elements to highlight entire path
   */
  getSelectedNodes = (id, type) => {
    return this.getParentIDs(this.props.flatFolderTree, id, type);
  }

  /**
   * TO REMOVE - duplicate from SEARCH
   */
  getExpandedNodes = (id, type) => {

    return this.getParentIDs(this.props.flatFolderTree, id, type);
  }

  /**
    *TO REMOVE - duplicate from SEARCH
    */
  populateExpandedNodes = (expandedNodes) => {
    if (!this.props.expandedNodes || (!expandedNodes || expandedNodes.length === 0)) return;
    //populate expanded nodes (keep an existing ones)
    expandedNodes.forEach(elementID => {
      this.props.expandedNodes.push(elementID);
    });
  }

  /**
   * TO REMOVE - duplicate from dataService
   */
  getParentIDs = (data, id, type) => {
    const parentIDs = [];

    while (id !== 0) {
      if (!isNaN(id) && !isNaN(type)) {
        const node = this.getNodeByID(data, parseInt(id), parseInt(type));
        if (node && node.parent) {
          parentIDs.push(node.id.toString());
          id = node.parent.id;
          type = node.parent.type
        }
      }
    }

    return parentIDs;
  }

  /**
   * TO REMOVE - duplicate from dataService
   */
  getNodeByID = (data, id, type) => {

    return data.find(element => {
      return element.id === id;
    });
  }

}

const mapStateToProps = (state) => {

  return {
    expandedNodes: state.expandedNodes,
    selectedNodes: state.selectedNodes,
    flatFolderTree: state.flatFolderTree,
    immutableFolderTree: state.immutableFolderTree,
    selectedButton: state.selectedButton,
    coinBaseURL: state.config.coinBaseURL,
    projectId: state.config.projectID
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setSelectedDossier: setSelectedDossier,
    setExpandedNodes: setExpandedNodes,
    setSelectedNodes: setSelectedNodes,
    setSelectedPowerBIObjectData: setSelectedPowerBIObjectData
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(MultiSelectTreeView));