import AbstractService from "./AbstractService";
import {dataUtility} from '../utils/DataUtility'

/**
 * Data service
 * 
 * This service allows to manipulate the data. It`s using data utility
 */
class DataService extends AbstractService {

    /**
     * Get flat tree structure.
     * 
     * @param {*} data 
     */
    getFlatTreeStructure = (data, favoriteObjects) => {
        let flatStrucutre = dataUtility.flattenTreeStructure(data);

        flatStrucutre = flatStrucutre.map(item => {
            return {
                ...item,
                favorite: this.isFavorite(item.id, favoriteObjects)
            }
        })

        return flatStrucutre;
    }

    isFavorite = (id, favoriteObjects) => {       
        if(favoriteObjects[id])
            return favoriteObjects[id];

        return false;
    }


    /**
     * Get node parents as an array.
     * 
     * @param {*} data 
     * @param {*} id 
     * @param {*} type 
     * @returns 
     */
    getParentIDs = (data, id, type) => {
        const parentIDs = [];
        while (id !== 0) {
            const node = this.getNodeByID(data, parseInt(id), parseInt(type));
            if (node && node.parent) {
                parentIDs.push(node.id.toString());
                id = node.parent.id;
                type = node.parent.type
            }
        }

        return parentIDs;
    }

    

    /**
     * Get node by ID.
     * 
     * @param {*} data 
     * @param {*} id 
     * @param {*} type 
     * @returns 
     */
    getNodeByID = (data, id, type) => {
        return data.find(element => {
            return element.id === id && element.type === type;
        });
    }
 
}

export const dataService = new DataService();