import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App, { APPS } from './App';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import initialState from './initialState';
import rootReducer from './rootReducer';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import WithConfiguration from './session/WithConfiguration';
import ImageGallary from "./components/ImageGallary/ImageGallary"
import ImageGallaryForm from "./components/ImageGallary/ImageGallaryForm";


const createStoreWithMiddleWare = applyMiddleware(thunk)(createStore);
let store = createStoreWithMiddleWare(rootReducer, { ...initialState });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <WithConfiguration>
        <Router>
          <Switch>


            <Route exact path="/rubik/embed/imageview">
              <ImageGallary />
            </Route>
            <Route exact path="/rubik/embed/imageForm">
              <ImageGallaryForm />
            </Route>

            <Route
              exact
              path={["/rubik/embed/mstr/:projectId/:dossierId/:pageKey", "/rubik/embed/mstr/:projectId/:dossierId"]}
              render={routeProps => <App {...routeProps} app={APPS.MSTR} />}
            />
            <Route
              exact
              path={["/rubik/embed/pbi/:workspaceId/:objectType/:objectId", "/rubik/embed/pbi/:workspaceId/:objectType/:objectId/:pageName", "/rubik/embed/pbi/:workspaceId/:objectType/:objectId/roles/:applyRoles", "/rubik/embed/pbi/:workspaceId/:objectType/:objectId/roles/:applyRoles/:pageName"]}
              render={routeProps => <App {...routeProps} app={APPS.PBI} />}
            />
            <Route path="/rubik/embed/*">
              <Redirect to="/rubik" />
            </Route>
            <Route>
              <App />
            </Route>
          </Switch>
        </Router>
      </WithConfiguration>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
