import React, { Component } from 'react'
import { Button, ButtonGroup } from '@material-ui/core';
import './../../../../fontello/css/fontello.css';
import './LeftBarButtonGroup.css';
import { connect } from 'react-redux';
import { setFilter, setFavoriteObjects, onError } from '../../../../actions'
import { bindActionCreators } from 'redux';
import errors from '../../../../errorTypes';
import { favoritesService } from '../../../../services/FavoritesService';

export const FILTER_BUTTONS_IDS = {
    ALL: 0,    
    CORE: 1,
    TOP: 2,
    EXTERNAL: 3,
    FAVORITE: 4
}

const FILTER_BUTTONS = [
    {
        id: FILTER_BUTTONS_IDS.ALL,
        icon: "icon-globe",
        tooltip: "All reports"
    }, {
        id: FILTER_BUTTONS_IDS.FAVORITE,
        icon: "icon-star-filled",
        tooltip: "Favorite reports"
    }, {
        id: FILTER_BUTTONS_IDS.CORE,
        icon: "icon-award",
        tooltip: "Core reports"
    }, {
        id: FILTER_BUTTONS_IDS.TOP,
        icon: "icon-award-1",
        tooltip: "Most popular reports"
    }, {
        id: FILTER_BUTTONS_IDS.EXTERNAL,
        icon: "icon-export",
        tooltip: "External reports"
    }
];

class LeftBarButtonGroup extends Component {

  
    onFilterButtonClick = (id) => {      
        this.props.setFilter(id);
    }

    setFavorite = async (objectId, isFavorite) => {
        if (isFavorite) {
            return favoritesService.addFavorite(this.props.config.mstrLibraryUrl, objectId);
        }
        else {
            return favoritesService.removeFavorite(this.props.config.mstrLibraryUrl, objectId);
        }
    }

    onFavoriteButtonClick = async(evt) => {
        let isFavorite = !(evt.currentTarget.attributes["isfavorite"].textContent === 'true');
       
        let favoriteObjects = this.props.favoriteObjects;
        // Rubik-707
        favoriteObjects[this.props.selectedObject.reportName] = isFavorite;
        // Rubik-707
        let response = await this.setFavorite(this.props.selectedObject.reportName, isFavorite);

        if (response.ok){
            this.props.setFavoriteObjects(favoriteObjects);
            this.forceUpdate();
        }
        else if (response.status === 401){
            this.props.onError({
                code: errors.AUTHENTICATION_ERROR.CODE,
                title: "Error",
                message: "Your MicroStrategy session has expired. Please reload the page to re-authenticate."
            })
        }
        else {
            this.props.onError({
                code: errors.ENDPOINT_ERROR.code,
                title: "Error",
                message: "Not able to add/remove from favourites. Please contact your administrator."
            })
        }
    }

    /**
     * We make a check to make sure that either true or false is sent. We do not want "undefined" to be returned.
     */
    // Rubik-707
     isFavorite = () => {
        let favoriteObjects = this.props.favoriteObjects;
       
        if(favoriteObjects[this.props.selectedObject.reportName])
            return favoriteObjects[this.props.selectedObject.reportName];

        return false;
    }

    /**
     * DUPLICATE FROM DATA SERVICE
     */
    getSelectedNodes = (nodeid, dataStructure, objectId) => {
        
        if(nodeid !== 0)
            for(let i = 0, size = dataStructure.length; i < size; i++) {
                let object = dataStructure[i];
                if(object.did === objectId)
                    return [(object.id + "")];
            }
        /*else 
            for(let i = 0, size = dataStructure.length; i < size; i++) {
                let object = dataStructure[i];
                
                let node = this.getNodeByDID(dataStructure, objectId);
           
                // if(object.ldescription) {
                //     let json = JSON.parse(object.ldescription);
                //        
                //     if(json.objectId === objectId)
                //         return this.getSelectedNodesOriginal(object.id, object.type);
                //     else if(object.children && object.children.length > 0)
                //         return this.getSelectedNodes(nodeid, object.children, objectId);
                // }
                    
            }*/
        
        
        return []; 
      }



    /**
     * Get parents node id for selected elements to highlight entire path
     * 
     * Duplicate from MultiSelectTreeView
     * 
     */
    getSelectedNodesOriginal = (id, type) => {
        return this.getParentIDs(this.props.immutableFolderTree, id, type); 
    }

    /**
     * TO REMOVE - duplicate from dataService
     */
    getParentIDs = (data, id, type) => {  
        const parentIDs = [];
     
        while (id !== 0) {
        if(!isNaN(id) && !isNaN(type)) {
            const node = this.getNodeByID(data, parseInt(id), parseInt(type));
            if (node && node.parent) {
                parentIDs.push(node.id.toString());
                id = node.parent.id;
                type = node.parent.type
            }
        }
        }

        return parentIDs;
    }

    /**
     * TO REMOVE - duplicate from dataService
     */
    getNodeByID = (data, id, type) => {

        return data.find(element => {
            return element.id === id;
        });
    }

    getNodeByDID = (data, id) => {

        return data.find(element => {

            if(element.ldescription) {
                let ldescription = JSON.parse(element.ldescription);
             
                return ldescription.objectId === id;
            }

            return element.id === id;
        });
    }

    render() {
        const isFavorite = this.isFavorite();

        const _buttons = FILTER_BUTTONS.map(button => {
            let style = (button.id === this.props.selectedButton) ? "LeftBarBtnGroup-btn-selected" : "LeftBarBtnGroup-btn-notSelected";
            let icon = button.icon;

            return <Button title={button.tooltip} onClick={() => this.onFilterButtonClick(button.id)} key={button.id} className={style}><span className={icon}></span></Button>
        });

        let favoriteIcon = isFavorite ? "icon-star-filled" : "icon-star";
        _buttons.push( <Button onClick={this.onFavoriteButtonClick} key={FILTER_BUTTONS.length}  isfavorite={isFavorite.toString()} className={"LeftBarBtnGroup-favorite"}><span className={favoriteIcon}></span></Button>);

        return (
            <div className={"LeftBarBtnGroup-container"}>
                <ButtonGroup size="small" aria-label="small outlined button group">
                  {_buttons}    
                </ButtonGroup>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
  
    return {
      selectedObject : state.selectedObject,
      favoriteObjects : state.favoriteObjects,
      selectedButton: state.selectedButton,
      config : state.config,
      mstrAuthToken : state.mstrAuthToken,
      userId : state.userId,
      immutableFolderTree : state.immutableFolderTree
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setFilter: setFilter,
        setFavoriteObjects: setFavoriteObjects,
        onError: onError
    }, dispatch);
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(LeftBarButtonGroup);