import { ACTIONS } from "./rootReducer";
import { authenticationService } from './services/AuthenticationService'
import { folderService } from './services/FolderService'
import {dataService} from './services/DataService'
import ERRORS from './errorTypes'
import { FILTER_BUTTONS_IDS } from "./components/Menu/MenuItems/ButtonGroup/LeftBarButtonGroup";
import { findItemByReportId } from "./utils/stateMethods";
import { deviceHelper } from './utils/DeviceHelper';

//loading configuration from config.json
export const loadConfig = () => {
    return async (dispatch) => {
        try {
            const timestamp = Date.now();
            const res = await fetch(`${process.env.PUBLIC_URL}/config.json?t=${timestamp}`);
            if (res.status === 200) {
                const config = await res.json();
                dispatch({
                    type: ACTIONS.LOAD_CONFIG,
                    payload: config
                });
            }
            else throw new Error("Failed to load configuration");
        }
        catch (error) {
            console.log(error);
            dispatch(onError({
                code: ERRORS.ENDPOINT_ERROR.CODE,
                title: "Error",
                message: `${ERRORS.ENDPOINT_ERROR.DESC} ${error.message}`
            }));
        }
    }
}

//setting auth token after logging in
//normally, this would call api/auth/login endpoint, 
//but since we will embed login page, we just need to update the state after capturing authToken from cookie
export const setAuthToken = (authToken, identityToken, user) => {
    return {
        type: ACTIONS.SET_AUTH_TOKEN,
        payload: {
            authToken: authToken,
            identityToken: identityToken,
            user: user
        }
    }
}

export const setUserDetails = (userDetails) => {
    return {
        type: ACTIONS.SET_USER_DETAILS,
        payload: userDetails
    }
}

export const setIdentityToken = (identityToken) => {
    return {
        type: ACTIONS.SET_IDENTITY_TOKEN,
        payload: identityToken
    }
}

export const setSelectedDossierId = (dossierId) => {

    return {
        type: ACTIONS.SET_DOSSIER_ID,
        payload: dossierId
    }
}

export const setSelectedDossierName = (dossierName) => {

    return {
        type: ACTIONS.SET_DOSSIER_NAME,
        payload: dossierName
    }
}

export const setSelectedDossier = (dossier) => {
    return {
        type: ACTIONS.SET_DOSSIER,
        payload: dossier
    }
}

export const showProgress = () => {
    return {
      type: ACTIONS.SHOW_PROGRESSBAR
    }
}
  
export const hideProgress = () => {
    return {
     type: ACTIONS.HIDE_PROGRESSBAR
    }
}

export const openHomePage = () => {
   
    return {
        type: ACTIONS.OPEN_HOME_PAGE
    }
}

export const setPowerBIToken = (powerBIToken) => {
  
    return {
        type: ACTIONS.SET_POWERBI_TOKEN,
        payload : {
            powerBIToken : powerBIToken
        }
        
    }
}

export const setSelectedPowerBIObjectData = (workspaceId, objectId, objectType, reportName, pageName, embedURL, alertMessage,isFilter) => {
    return (dispatch, getState) => {
        const state = getState();

        const selectedObject = findItemByReportId(state, objectId);
        let applyRoles = "";
        let isFilter = true
        if(selectedObject){
            if(selectedObject.longDescriptionProps){
                 applyRoles = selectedObject.longDescriptionProps.applyRoles;
                 isFilter = selectedObject.longDescriptionProps.isFilter;
            }
        }
        
        //Rubik-718

        dispatch({
            type: ACTIONS.SET_POWERBI_REPORT_DATA,
            payload: {
                workspaceId : workspaceId,
                objectId : objectId,
                objectType : objectType,
                embedURL : embedURL,
                pageName : pageName,
                applyRoles : applyRoles,
                reportName : reportName,
                alertMessage: alertMessage,
                isFilter:isFilter
            }
        });
    }
}

//Rubik-700 Issues with Share function
export const setSelectedPowerBIObjectDataAction = (workspaceId, objectId, objectType, pageName, applyRoles,isFilter) => {
    return (dispatch, getState) => {
        const state = getState();
        
        let applyRolesArr = [];
        applyRolesArr.push(applyRoles)
        console.log(applyRolesArr)
        dispatch({
            type: ACTIONS.SET_POWERBI_REPORT_DATA,
            payload: {
                workspaceId : workspaceId,
                objectId : objectId,
                objectType : objectType,
                embedURL : undefined,
                pageName : pageName,
                applyRoles : applyRolesArr,
                isFilter: isFilter
            }
        });
    }
}

export const setExpandedNodes = (expandedNodes) => {
  
    return {
        type: ACTIONS.SET_DEFAULT_EXPANDED_NODES,
        payload: {
            expandedNodes
        }
    }
}

export const showAlert = (alertStatus) => {
    return {
      type: ACTIONS.SET_ALERT_STATUS,
      payload: alertStatus
    }
}


export const setSelectedNodes = (selectedNodes) => {
 
    return {
        type: ACTIONS.SET_TREE_NODE_SELECTED,
        payload: {
            selectedNodes
        }
    }
}



export const setAuthenticated = (authToken = null) => {
    return {
        type: ACTIONS.SET_AUTHENTICATED,
        payload: {
            authToken
        }
    }
}

export const setFilter = (selectedFilter) => {
    return (dispatch, getState) => {
        const state = getState();

        let dataStructure = state.immutableFolderTree;

        let flatView = state.flatFolderTree;
        // Rubik-496
        //Rubik-712
        const isMobile = deviceHelper.isMobile();
        const isTablet = deviceHelper.isTablet();
        if (isMobile) {
            flatView = flatView.filter(data => {
                if(data.longDescriptionProps !== undefined)
                   return data.longDescriptionProps["mobileView"] == undefined
                  || data.longDescriptionProps["mobileView"] === true 
            });
         } else if (isTablet) {
            flatView = flatView.filter(data => {
                if (data.longDescriptionProps !== undefined)
                    return data.longDescriptionProps["tabletView"] == undefined
                        || data.longDescriptionProps["tabletView"] === true
            });
        } else {
            flatView = flatView.filter(data =>{
                if(data.longDescriptionProps !== undefined)
                 return data.longDescriptionProps["desktopView"] == undefined 
                 || data.longDescriptionProps["desktopView"] === true 
            });
        }

        switch (selectedFilter){
        case FILTER_BUTTONS_IDS.ALL:
            break;
        case FILTER_BUTTONS_IDS.FAVORITE:
            dataStructure = flatView.filter(item => isFavorite(state, item)).map(item => fixDisplayName(item)); 
            console.log("data ",dataStructure)
            break;
        case FILTER_BUTTONS_IDS.CORE:
            dataStructure = flatView.filter(item => item.core).map(item => fixDisplayName(item)); 
            break;
        case FILTER_BUTTONS_IDS.TOP:
            dataStructure = flatView.filter(item => item.top).map(item => fixDisplayName(item)); 
            break;
        case FILTER_BUTTONS_IDS.EXTERNAL:
            dataStructure = flatView.filter(item => item.external).map(item => fixDisplayName(item)); 
            break;
        default:
            break; 
        }

        dispatch({
            type: ACTIONS.SET_SELECTED_BUTTON,
            payload: {
                selectedButton : selectedFilter,
                folderTree: dataStructure
            }
        });
    }    
}


//on error action handler
export const onError = (error) => {
    console.error(error);
    return {
        type: ACTIONS.DISPLAY_MESSAGE,
        payload: {
            error
        }
    }
}


//on authenticate action handler
export const onAuthenticate = (config) => {
    return async (dispatch) => {
        try {
            const res = await authenticationService.getTokenFromCookie(config);
            if (!res.ok) {
                throw new Error('Get token from cookie request error');
            }
            const authToken = res.headers.get('X-MSTR-AuthToken');

            dispatch({
                type: ACTIONS.SET_AUTHENTICATED,
                payload: {
                    authToken
                }
            });
        } catch (error) {
            console.log(error);
            dispatch(onError({
                code: ERRORS.AUTHENTICATION_ERROR,
                title: "Error",
                message: `${ERRORS.AUTHENTICATION_ERROR_DESC} ${error.message}.Please contact Administrator.`
            }));
        }   
    }
}

export const onStandardAuthenticate = (config) => {
    return async (dispatch) => {
            let url = `${config.mstrLibraryUrl}/api/auth/login`;
           
            let data = "{\"username\":\"administrator\",\"password\":\"\",\"loginMode\":1}";
 
            const response = await fetch(url, {
                method : "POST",
                headers : {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
    
                },
                body: data
            });
            const authToken = response.headers.get('X-MSTR-AuthToken');
            dispatch({
                type: ACTIONS.SET_AUTHENTICATED,
                payload: {
                    authToken
                }
            });
    }
}

export const setFavoriteObjects = (favoriteObjects) => {
 
    return {
        type: ACTIONS.SET_FAVORITE_OBJECTS,
        payload: {
            favoriteObjects
        }
    }
}

//on tree load  handler
export const onTreeStructureLoad = (config, mstrAuthToken) => {
    return async (dispatch, getState) => {
        try {
            let state = getState();

            let identityToken = state.identityToken;

            if (!identityToken){
                identityToken = await authenticationService.getIdentityToken(config, mstrAuthToken);

                dispatch({
                    type: ACTIONS.SET_IDENTITY_TOKEN,
                    payload: identityToken
                });
            }

            let res = await folderService.fetchFolders(config, identityToken);
            if (!res.ok) {
                throw new Error('Fetch folder end-point return an exception. Please contact Administrator.');
            }
            //retrive promise
            const data = await res.json();

            let expandedNodes = [];
            let selectedNodes = [];

            state = getState();

            if (state.selectedObject.objectId) {
                const parents = getPath(state.selectedObject.objectId, data.children);

                if (parents){
                    expandedNodes = parents;
                    selectedNodes = parents;    
                }
            }

            dispatch({
                type: ACTIONS.SET_TREE_STRUCTURE,
                payload: {
                    folderTree: data.children,
                    favoriteObjects: data.favoriteObjects,
                    userId: data.userId,
                    selectedNodes: selectedNodes,
                    expandedNodes: expandedNodes
                }
            });
               //dispatch flat structure
            let flatStructure = dataService.getFlatTreeStructure(data.children, data.favoriteObjects);
            
            // Rubik-496
            //Rubik-712
            const isMobile = deviceHelper.isMobile();
            const isTablet = deviceHelper.isTablet();

            if (isMobile) {
                flatStructure = flatStructure.filter(data => {
                    if(data.longDescriptionProps !== undefined)
                       return data.longDescriptionProps["mobileView"] == undefined
                      || data.longDescriptionProps["mobileView"]  === true 
                });
             } else if (isTablet) {
                flatStructure = flatStructure.filter(data => {
                    if (data.longDescriptionProps !== undefined)
                        return data.longDescriptionProps["tabletView"] == undefined
                            || data.longDescriptionProps["tabletView"] === true
                });
            } else {
                flatStructure = flatStructure.filter(data =>{
                    if(data.longDescriptionProps !== undefined)
                     return data.longDescriptionProps["desktopView"] == undefined 
                     || data.longDescriptionProps["desktopView"] === true 
                });
            }
       
            dispatch({
                type: ACTIONS.SET_FLAT_TREE_STRUCTURE,
                payload: {
                    flatFolderTree: flatStructure
                }
            });

           
        }
        catch (error) {
            console.log(error);
            dispatch( onError({
                code: ERRORS.ENDPOINT_ERROR,
                title: "Error",
                message: error.message
            }));
        }
    }
}

export const turnOffPowerBI = () => {
 
    return {
        type: ACTIONS.TURN_OFF_POWERBI
    }
}

export const setEmbeddedDossierStatus = (status) => {
    return {
        type: ACTIONS.SET_EMBEDDED_DOSSIER_STATUS,
        payload: status
    }
}
//Rubik-707
const isFavorite = (state, item) => {
    const favoriteObjects = state.favoriteObjects;

    const id = item.longDescriptionProps.displayName;
    
    if(id && favoriteObjects[id]){
        return favoriteObjects[id];
    }
    else return false;
}

/*
    This method returns an array of all items in line from root to the searched item id i.e. all parents of an item + the item itself
*/
const getPath = (id, folderTree) => {
    let path = [];

    for (let i = 0; i < folderTree.length; i++){
        const item = folderTree[i];

        const ldescription = item.ldescription ? JSON.parse(item.ldescription) : null;

        if (item.did === id || (ldescription && (ldescription.objectId === id))) {
            path = [item.id.toString()];
        }

        else if (item.children && item.children.length){
            const parents = getPath(id, item.children);

            if (parents && parents.length){
                path = [...parents, item.id.toString()];
            }            
        }

        if (path.length) {
            return path;
        }
    }

    return path;
}

/**
 * If item in the tree contains "displayName" property in Long description, replace name accordingly.
 * 
 * @param {JSXElement} item 
 * @returns 
 */
const fixDisplayName = (item) => {
    let longDescription = item.longDescriptionProps;

    if(longDescription && longDescription.displayName)
        item.name = longDescription.displayName;

    return item;
}

