import React, { Component } from 'react'

import { deviceHelper } from '../../../../utils/DeviceHelper';
import StyledTreeItem from './StyledTreeItem'
import MailIcon from '@material-ui/icons/Mail';
import AppsIcon from '@material-ui/icons/Apps';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ComputerIcon from '@material-ui/icons/Computer';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import PeopleIcon from '@material-ui/icons/People';
import StoreIcon from '@material-ui/icons/Store';
import PageviewIcon from '@material-ui/icons/Pageview';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import WatchIcon from '@material-ui/icons/Watch';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import GavelIcon from '@material-ui/icons/Gavel';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightness';

import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BusinessIcon from '@material-ui/icons/Business';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import PieChartIcon from '@material-ui/icons/PieChart';
import DescriptionIcon from '@material-ui/icons/Description';

const icons = {
    "MailIcon": MailIcon,
    "AppsIcon": AppsIcon,
    "AssignmentIcon": AssignmentIcon,
    "AssessmentIcon": AssessmentIcon,
    "ComputerIcon": ComputerIcon,
    "BubbleChartIcon": BubbleChartIcon,
    "PeopleIcon": PeopleIcon,
    "StoreIcon": StoreIcon,
    "PageviewIcon": PageviewIcon,
    "ScheduleIcon": ScheduleIcon,
    "CreditCardIcon": CreditCardIcon,
    "WatchIcon": WatchIcon,
    "AttachMoneyIcon": AttachMoneyIcon,
    "AccountCircleIcon": AccountCircleIcon,
    "FlightTakeoffIcon": FlightTakeoffIcon,
    "ApartmentIcon": ApartmentIcon,
    "ContactMailIcon": ContactMailIcon,
    "ImportantDevicesIcon": ImportantDevicesIcon,
    "GavelIcon": GavelIcon,
    "LocalDrinkIcon": LocalDrinkIcon,
    "ShoppingBasketIcon": ShoppingBasketIcon,
    "SettingsBrightnessIcon": SettingsBrightnessIcon,

    "MonetizationOnIcon" : MonetizationOnIcon,
    "BusinessIcon" : BusinessIcon,
    "LocalMallIcon" : LocalMallIcon,  
    "SupervisedUserCircleIcon" : SupervisedUserCircleIcon,
    "LinearScaleIcon" : LinearScaleIcon,
    "PieChartIcon" : PieChartIcon,
    "LightbulbCircleIcon" : LightbulbCircleIcon,
    "DescriptionIcon":DescriptionIcon
}


/**
 * MultiTreeView to render tree recursively
 */
class MultiTreeView extends Component {

    render = () => {
        return <React.Fragment>
            {
                this.props.nodes.map(node => {
                    return this.renderTree(node);
                })
            }
        </React.Fragment>
    }

    /**
     * Browse folder recursively.
     * 
     * @param {*} folders 
     */
    renderTree = data => {
        //populate nodes properties
        const objectProps = this.getObjectProps(data);
        //render tree nodes
        if (this.hasChildren(data) && objectProps != null) {
            return (
                <StyledTreeItem {...objectProps}
                    className="treeNode"
                    onContextMenu={(evt) => this.onContextMenu(evt, objectProps.nodeId)}               
                >
                    {data.children.map((node, idx) => this.renderTree(node))}
                </StyledTreeItem>
            );
        } else if(objectProps != null){
            return <StyledTreeItem 
            {...objectProps}
            className="treeNode"
            onContextMenu={(evt) => this.onContextMenu(evt, objectProps.nodeId)}               
        />;
        }
    }

    onContextMenu = (evt, id) => {
        evt.preventDefault();
        evt.stopPropagation();
        this.props.onContextMenu(evt, id);
    }


    /**
     * Check if the node has children
     * @param {*} node 
     * @returns 
     */
    hasChildren = (node) => {
        return node.children && node.children.length;
    }


    /**
     * Get object props to be rendered
     */
    getObjectProps = (data) => {
        let object = null;
        // Rubik-496
        const isMobile = deviceHelper.isMobile();
        const isTablet = deviceHelper.isTablet();

        if (data.ldescription) {
            //controlling the mobile and desktop view
            object = JSON.parse(data.ldescription);
           console.log(object);
           console.log("-------------");
            if(isMobile){
               if(object["mobileView"] == false){
                return null
               }
             } else if (isTablet) {
                if (object["tabletView"] == false) {
                    return null
                }
            } else{
                if(object["desktopView"] == false){
                    return null
                }
             }
        }
        const icon = object && icons[object.icon] ? icons[object.icon] : MailIcon;
        const id = data.did ? data.did : object && object.objectId ? object.objectId : null;
        const type = data.type ? data.type : object && object.objectId ? object.type : null;

        const displayName = (object && object.displayName) ? object.displayName : data.name;
        const alertMessage = (object && object.alertText) ? object.alertText : null;

        //RUBIK-680 Favorites Menu displays some Dashboards multiple times
        const objectProps = {
            key: data.id.toString(),
            nodeId: data.id.toString(),
            id,
            type
        }

        //Rubik-640
        
        if(object && object.pageMenu){
            objectProps.pageMenu = object.pageMenu.toString()
        }
        if(object && object.paprojectid){
            objectProps.paprojectid = object.paprojectid;
        }

        if (data && data.powerBIId && data.powerBIType && data.powerBiWorkspaceId) {
            objectProps.id = data.powerBIId;
            objectProps.pbobjecttype = data.powerBIType;
            objectProps.pbworkspaceid = data.powerBiWorkspaceId;
        }

        if(data && data.isFilter){
            objectProps.isFilter = data.isFilter;
        }

        if (object && object.workspaceId) {
            objectProps.pbembedurl = object.embedURL;
            objectProps.pbobjecttype = object.type;
            objectProps.pbworkspaceid = object.workspaceId
        }
        // if (object && object.favoriteId) {
        //     objectProps.favoriteId = object.favoriteId;
        // }
        if (object && object.icon) {
            objectProps.labelText = displayName;
            objectProps.labelIcon = icon;
        } else {
            objectProps.label = displayName;

        }
        objectProps.title = displayName;
        objectProps.alertMessage = alertMessage

        return objectProps;
    }
}


export default MultiTreeView;

