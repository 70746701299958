import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onError, setPowerBIToken, turnOffPowerBI } from '../actions';
import { redirectionHelper } from './RedirectionHelper';
import { powerBIService } from '../services/PowerBIService';
import errors from '../errorTypes';

class AuthenticatedByPBI extends Component {

    render(){
        //render children only if PBI session is established or PBI is off
        return this.props.powerBIToken || !this.props.enablePowerBI ? this.props.children : null;
    }

    componentDidMount(){
        this.props.enablePowerBI && this.authenticate();
    }

    //this component should not update unless powerBIToken is changed, which should only happen at log off
    componentDidUpdate() {
        this.props.enablePowerBI && (!this.props.powerBIToken && this.authenticate());
    }    

    authenticate = async () => {
        const accessToken = await powerBIService.getAccessTokenAppOwnsData(this.props.config, this.props.identityToken);

        if (!accessToken){
            this.props.onError({
                code: errors.AUTHENTICATION_ERROR.CODE,
                title: "PBI Authentication error",
                message: "Unable to authenticate to Power BI. Power BI reports will not be available."
            });
        }

        this.props.setPowerBIToken(accessToken);
    }

    onAuthenticated = (accessToken) => {
        //this method will verify if there's a target location set in cookie and redirect if needed
        redirectionHelper.redirectToTargetLocation();

        this.props.setPowerBIToken(accessToken);
    }
}

function mapStateToProps(state){
    return {
        config: state.config,
        identityToken: state.identityToken,
        powerBIToken: state.powerBIToken,
        enablePowerBI: state.enablePowerBI
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        onError: onError,
        setPowerBIToken: setPowerBIToken,
        turnOffPowerBI: turnOffPowerBI
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedByPBI);