import React, { Component } from 'react'
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import DashboardStyle from '../../../Dashboard/DashboardStyle';
import './SideBarTitle.css';

/**
 * Side bar title for the left panel menu. It contains title, hamburger and close panel icon
 */
export class SideBarTitle extends Component {

    render = () => {
        const classes = this.props.classes;
        return (
            <div className={classes.toolbarIcon} >
                {/* App logo for left panel menu */}
                <MenuIcon className="sideBarIcon" />
                <div className="sidebar-title">Sidebar</div>
                <IconButton onClick={this.props.onClose}>
                    <ChevronLeftIcon className="sideBarIcon" />
                </IconButton>
            </div>
        )
    }
}

export default withStyles(DashboardStyle)(SideBarTitle)
