import { Component } from 'react';
import AuthenticatedByMSTR from './AuthenticatedByMSTR';
import AuthenticatedByPBI from './AuthenticatedByPBI';



class Authenticated extends Component {

    render() {
        return <AuthenticatedByMSTR>
            <AuthenticatedByPBI>

                {this.props.children}

            </AuthenticatedByPBI>
        </AuthenticatedByMSTR>
    }
}


export default Authenticated;