class DataUtility {
   

    flattenTreeStructure(data){
        let flatStructure = [];

        data.forEach(element => {
            if (element.name) {
                let elementData = {
                    name: element.name,
                    did: element.description ? element.description : element.did,
                    parent: element.parent,
                    type: element.type,
                    id: element.id                    
                };

                if(element.ldescription) {
                    const longDescription = JSON.parse(element.ldescription);
                    
                    elementData = {
                        ...elementData,
                        top: longDescription.top,
                        core: longDescription.core,
                        external: longDescription.external,
                        longDescriptionProps: longDescription
                    }

                    if (longDescription.workspaceId){//PBI report
                        elementData = {
                            ...elementData,
                            powerBIId : longDescription.objectId,
                            powerBIType : longDescription.type,
                            powerBiWorkspaceId : longDescription.workspaceId
                        }
                    }
                    else {//folder or MSTR dossier
                        elementData = {
                            ...elementData,
                            did: longDescription.objectId,
                            type: longDescription.type
                        }
                    }
                };

                flatStructure.push(elementData);

                if (element.children && element.children.length){
                    flatStructure = flatStructure.concat(this.flattenTreeStructure(element.children));
                }
            }
        });

        return flatStructure;
    }

    flatArrFavorites = (data, arr, favoriteObjects) => {
        
        data.forEach(element => {

            let dataElement = {
                name: element.name,
                did: element.description ? element.description : element.did,
                parent: element.parent,
                type: element.type,
                id: element.id
            };

            let verificationId = dataElement.did;


            if(element.ldescription) {
                let longDescription = JSON.parse(element.ldescription);

                if(element.isFavorite || favoriteObjects[longDescription.reportName]) {


                    let displayName = element.name;
                  
                    if(longDescription.displayName)
                        displayName = longDescription.displayName;
    
                    if(longDescription.objectId && longDescription.type && longDescription.workspaceId) {
                        dataElement = {
                            name: displayName,
                            did: element.description ? element.description : element.did,
                            parent: element.parent,
                            type: element.type,
                            id: element.id,
                            powerBIId : longDescription.objectId,
                            powerBIType : longDescription.type,
                            powerBiWorkspaceId : longDescription.workspaceId
                        };
    
                        verificationId = longDescription.objectId;
                    }

                    if(longDescription && longDescription.isFilter){
                        dataElement = {
                            powerBiFilter : longDescription.isFilter
                        }
                    }
                    
                    if(!verificationId && longDescription.objectId && longDescription.type) {
                        dataElement = {
                            name: displayName,
                            did: longDescription.objectId,
                            parent: element.parent,
                            type: longDescription.type,
                            id: element.id
                        };
                        verificationId = longDescription.objectId;
                    }    
                }

            }

            
           
            if(favoriteObjects[verificationId])
                arr.push(dataElement);

            if (this.hasChildren(element)) 
                this.flatArrFavorites(element.children, arr, favoriteObjects);
            
        });

        return arr;
    }

    isEmpty = (str) => {
        return (!str || str.length === 0);
    }

    /**
    * Check if the node has children
    * @param {*} node
    * @returns
    */
    hasChildren = (node) => {
        return node.children && node.children.length;
    }
}

export const dataUtility = new DataUtility();