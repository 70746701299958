import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadConfig } from '../actions';

class WithConfiguration extends Component {

    render(){
        return this.props.config && this.props.children;
    }

    componentDidMount() {
        if (!this.props.config) {
            this.props.loadConfig();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.config,
    };
}
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loadConfig: loadConfig,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WithConfiguration);