import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useStyles from './MessageStyle';
import { onError, setAuthToken, setEmbeddedDossierStatus } from '../../actions'
import ERRORS from '../../errorTypes';



/**
 * Message component being used to display errors and messages
 */
class Message extends Component {

  handleClose = () => {
    if (this.props.error.code === ERRORS.DOSSIER_ERROR.CODE){
      this.refreshDossier();
    }
    this.props.onError(null, "");
  };


  render = () => {
    if (!this.props.error || !this.props.error.code){
      return null;
    }

    const linkStyle = {
      textDecoration: "underline",
      cursor: "pointer",
    }

    const { classes } = this.props;
    this.body = (
      <div className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.title}>{this.props.error.title}</h2>
        <p id="simple-modal-description">
          <div>{this.props.error.message}</div>
        </p>
        {this.props.error.code  === ERRORS.DISABLE_REFRESH.CODE && <span></span>}
        {this.props.error.code === ERRORS.AUTHENTICATION_ERROR.CODE && <a style={linkStyle} onClick={() => this.logout()}>Reload</a>}
        {this.props.error.code === ERRORS.DOSSIER_ERROR.CODE && <a style={linkStyle} onClick={this.refreshDossier}>Refresh</a>}
        {this.props.error.code === ERRORS.SESSION_EXPIRE.CODE && <a style={linkStyle} onClick={() => window.location.reload()}>Refresh</a>}
      </div>
    );

    return (
      <div>
        <Modal
          open={this.props.error.code ? true : false}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {this.body}
        </Modal>
      </div>
    );
  }

  refreshDossier = () => {
    this.props.setEmbeddedDossierStatus(0)
  }

  logout() {
    this.handleClose();
    this.props.setAuthToken(null);    
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    onError: onError,
    setAuthToken: setAuthToken,
    setEmbeddedDossierStatus: setEmbeddedDossierStatus
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Message));
