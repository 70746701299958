const initialState = {
    favoriteObjects : {},
    selectedButton : 0,
    expandedNodes : [],
    selectedNodes: [],
    config: null,
    mstrAuthToken: null,
    user: null,
    powerBIToken: null,
    identityToken: null,
    selectedObject: {
        type: null,
        projectId: null,
        objectId: null,
        pageName: null,
        embedUrl: null,
        applyRoles: false,
        reportName: null,
        pageMenu: null,
        alertMessage: null,
        isFilter:true
    },
    embeddedDossierStatus: null,
    folderTree:[],
    immutableFolderTree : [],
    flatFolderTree:[],
    error:{
        code:null,
        title: null,
        message:null
    },
    userId : null,
    enablePowerBI : true,
    searchExpanded : false,
    progressBar:false,
    userInfoDetails:null,
    alert: true
}

export default initialState;