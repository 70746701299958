import { withStyles } from "@material-ui/core";
import { Component } from "react";

const useStyles = (theme) => ({
    icon: {
        margin: '15px 0px',
        color: '#959595',
        cursor: 'pointer'
    },
});

class IconLink extends Component {

    render = () => {
        const { classes } = this.props;

        return <a className={classes.icon} title={this.props.title} href={this.props.href} target='_blank'>{this.props.children}</a>
    }
}

export default withStyles(useStyles)(IconLink);