import React, { Component } from 'react'
import logo from '../../images/data logo dark.gif'
import './Logo.css'
import { openHomePage } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/**
 * Customer`s logo
 */
class Logo extends Component {

  // constructor(props) {
  //   super(props);
  //   this.click = this.click.bind(this);
  // }

  click = () => {
    this.props.openHomePage();
  }

  render = () =>{

      return (
        <div className = "logo" onClick={this.click} >
          <img width={this.props.size} height={this.props.size} src={logo} alt="DFS" />
        </div>   
      )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    openHomePage: openHomePage
  }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(Logo);


