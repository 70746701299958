import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import '../../../../fontello/css/fontello.css';

const useStyles = (theme) => ({
    root: {
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            color: "#EDEDED",
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        // padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
        //  flexGrow: 1,
    },
});

export class StyledTreeItem extends Component {
    render() {

        let {classes, label, labelIcon: LabelIcon, labelInfo, color, bgColor, top, labelText, ...other } = this.props;
        labelText = labelText || label;

        const icon = LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />

        const _label = <div className={classes.labelRoot}>
            {icon}
            {labelText && <Typography variant="body2" className={classes.labelText}>{labelText}</Typography>}
        </div>


        return (
            <TreeItem
                label={_label}             
                {...other}
            />
        )
    }
}

export default withStyles(useStyles)(StyledTreeItem)

