import AbstractService from "./AbstractService";

/**
 * Authentication service (embedded API)
 */
class AuthenticationService extends AbstractService {

    /**
     * Standard authentication
     */
    standardAuthentication = async (config, body) => {
        //Call REST auth login endpoint

        let loginAPIURL = `${config.mstrLibraryUrl}/api/auth/login`;

        return this.sendRequest(loginAPIURL, 'POST', {
            "Content-type": "application/json",
            "accept": "application/json"
        }, body)
    }

    authenticationLogout = async (config, mstrAuthToken) => {
        //Call REST auth login endpoint

        let loginAPIURL = `${config.mstrLibraryUrl}/api/auth/logout`;

        return this.sendRequest(loginAPIURL, 'POST', {
            "Content-type": "application/json",
            "accept": "application/json",
            "X-MSTR-AuthToken": mstrAuthToken
        })
    }

    /**
     * Get identityToken
     * 
     * @param {*} config 
     * @returns 
     */
    getIdentityToken = async (config, authToken) => {
        const identityTokenURL = `${config.mstrLibraryUrl}/api/auth/identityToken`;
        const res = await this.sendRequest(identityTokenURL, 'POST', {
            "Content-type": "application/json",
            "accept": "application/json",
            'X-MSTR-AuthToken': authToken,
        }, {});

        if (!res.ok) {
            throw new Error('Get token from cookie request error');
        }

        const identityToken = res.headers.get('X-MSTR-IdentityToken');

        return identityToken;
    }

    /**
     * GEt authorization token from cookie
     */
    getTokenFromCookie = (config) => {
        const options = {
            method: "GET",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
        }
        return fetch(`${config.mstrLibraryUrl}/api/auth/token`, options);
    }

    /**
     * Validate if token is valid.
     * 
     * @param {*} config 
     * @returns 
     */
    isAuthTokenValid = (config) => {
        try {
            
            const res =  this.getTokenFromCookie(config);
    
            if (!res.ok) {
                throw new Error('Get token from cookie request error');
            }
            const authToken = res.headers.get('X-MSTR-AuthToken');
            if(authToken) {
                return true;
            }
        }catch(error) {
            console.error(error);
            return false;
        }
    }

    /**
     * Transforms a cookie into authToken string.
     * @param {*} config 
     * @returns authToken string, if cookie exists, and null otherwise
     */
    getAuthToken = async (config) => {
        const firstAttempt = await this.token(config);

        if (firstAttempt.ok) {
            let authToken = firstAttempt.headers.get('X-MSTR-AuthToken');

            if (!authToken){
                //if we're here, it means that session was created based on mstrRememberMe cookie - we have to call /api/auth/token again to obtain authToken header
                const secondAttempt = await this.token(config);

                if (secondAttempt.ok) {
                    authToken = secondAttempt.headers.get('X-MSTR-AuthToken');
                }
            }

            return authToken;
        }
        else return null;
    }

    getUserInfo = async (config, authToken) => {
        const headers = {
            "X-MSTR-AuthToken": authToken,
            "Accept": "application/json"
        }

        const url = `${config.mstrLibraryUrl}/api/sessions/userInfo`;

        const res = await this.sendRequest(url, "GET", headers);
        
        if (res.ok) {
            const json = await res.json();
            return json;
        }
        else return null;
    }

    getUser = async (userId, config, authToken) => {
        const headers = {
            "X-MSTR-AuthToken": authToken,
            "Accept": "application/json"
        }

        const url = `${config.mstrLibraryUrl}/api/users/${userId}`;

        const res = await this.sendRequest(url, "GET", headers);
        
        if (res.ok) {
            const json = await res.json();
            return json;
        }
        else return null;
    }

    token = async (config) => {
        const options = {
            method: "GET",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
        }
        return await fetch(`${config.mstrLibraryUrl}/api/auth/token`, options);
    }

    /**
     * CHecks if MSTR session is alive
     * @param {*} config 
     * @param {*} authToken 
     * @returns true session is alive
     */
    isSessionAlive = async (config, authToken) => {

        const sessionsAPIURL = `${config.mstrLibraryUrl}/api/sessions`;
        const res = await this.sendRequest(sessionsAPIURL, 'GET', {
            "accept": "application/json",
            "X-MSTR-AuthToken": authToken
        });

        return res.ok;
    }
}

export const authenticationService = new AuthenticationService();