import React, { Component } from "react";
import { connect } from "react-redux";
import notification from "../../images/notification.png";
import closeIcon from "../../images/Close-Icon.png";
import "../Frame/Frame.css";

class DashboardAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: true,
      alertMessageBox: false,
      localAlert: "",
    };
  }

  getResult = () => {
    let data = JSON.parse(
      localStorage.getItem(this.props.selectedObject.reportName)
    );

    // if (this.state.alert) {
    if (this.props.selectedObject.alertMessage) {
      if (data) {
        console.log("inside if");
        if (this.props.selectedObject.alertMessage != data.alertText) {
          console.log("inside if compare");
          return true;
        } else {
          console.log("inside else compare");
          return data.alertStatus;
        }
      } else {
        console.log("inside else");
        return true;
      }
    }
    // }
  };

  render() {
    let result = this.getResult();
    console.log("result", result);
    console.log("------------------------------");
    return (
      <>
        {result && (
          <div className="DashboardAlertLayout">
            {this.state.alertMessageBox && (
              <div className="DashboardAlert">
                <div className="alertTextLayout">
                  <span className="alert-text">
                    {this.props.selectedObject.alertMessage}
                  </span>
                </div>

                <img
                  src={closeIcon}
                  className="closeIconNotification"
                  onClick={() => this.closeAlert()}
                />
              </div>
            )}
            <img
              src={notification}
              className="DashboardAlertImage"
              onClick={() => this.openAlertMessageBox()}
            />
          </div>
        )}
      </>
    );
  }

  closeAlert = () => {
    const alertData = {
      alertText: this.props.selectedObject.alertMessage,
      alertStatus: false,
    };

    let localStorageName = this.props.selectedObject.reportName;

    localStorage.setItem(
      localStorageName.toString(),
      JSON.stringify(alertData)
    );
    this.setState({ alert: !this.state.alert });
  };

  openAlertMessageBox = () => {
    this.setState({ alertMessageBox: !this.state.alertMessageBox });
  };
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    selectedObject: state.selectedObject,
  };
};

export default connect(mapStateToProps)(DashboardAlert);
