 const errors = {
    ENDPOINT_ERROR :{
        CODE: 1,
        DESC: "Endpoint error."
    },
    
    AUTHENTICATION_ERROR: {
        CODE : 2,
        DESC: "Authentication error."
    },
    
    CONFIGURATION_ERROR: {
        CODE: 3,
        DESC: "Configuration error."
    },
    TOKEN_ERROR: {
        CODE: 4,
        DESC: "Token error."
    },
    POWER_BI_TURNED_OFF: {
        CODE: 5,
        DESC: "PowerBI not available."
    },
    DOSSIER_ERROR: {
        CODE: 6,
        DESC: "Dossier error"
    },
    SESSION_EXPIRE: {
        CODE: 7,
        DESC: "Session expire"
    },
    DISABLE_REFRESH: {
        CODE: 8,
        DESC: "disable refresh"
    }
    
 };

export default errors;