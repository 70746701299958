import Dashboard from './components/Dashboard/Dashboard';
import { Component } from 'react'
import Message from './components/Message/Message';
import './App.css'
import Authenticated from './session/Authenticated';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSelectedDossier, setSelectedPowerBIObjectData, setSelectedPowerBIObjectDataAction } from './actions';
import { deviceHelper } from './utils/DeviceHelper';


export const APPS = {
  MSTR: 1,
  PBI: 2
}

/**
/**
 * Main App component
 */
class App extends Component {

  
  render() {
    window.rubikVersion = "1.94";
    return <div className="App">
      <Message />
      <Authenticated>
        <Dashboard />
      </Authenticated>
    </div>
  }
  
  componentDidUpdate() {
    if (this.props.match && this.props.app) {
      const params = this.props.match.params;

      switch (this.props.app) {
        case APPS.MSTR:
          this.selectMSTRDossier(params);
          break;
        case APPS.PBI:
          this.selectPBIReport(params);
          break;
        default:
          break;
      }
    }
    else {
      //embedding the default dossier - just in case
      //Rubik-639
      const isMobile = deviceHelper.isMobile();
      const isTablet = deviceHelper.isTablet();
      let dataWWdsf = "";
      let dataStorePer = "";
      if (this.props.userInfoDetails) {
        if (this.props.userInfoDetails["memberships"]) {
          dataWWdsf = this.props.userInfoDetails["memberships"].filter(data => {        
            return this.props.config["wwdfsLandingAccess"].includes(data["name"])
          })
          dataStorePer = this.props.userInfoDetails["memberships"].filter(data => {
            return this.props.config["storePerformanceAccess"].includes(data["name"])
          })
        }
      }
      dataWWdsf = dataWWdsf.length ? true : false;
      dataStorePer = dataStorePer.length ? true : false;

      let bothGroup = dataWWdsf == dataStorePer ? true : false;
      if (bothGroup) {
        if (isMobile) {
          this.mstrDossierParam(this.props.config.defaultLandingMobileDossierProjectID, this.props.config.defaultLandingMobileDossierID, this.props.config.defaultLandingMobilePageKey)
        } else if (isTablet) {
          this.mstrDossierParam(this.props.config.defaultLandingIpadDossierProjectID, this.props.config.defaultLandingIpadDossierID, this.props.config.defaultLandingIpadPageKey)
        }
        else {
          this.mstrDossierParam(this.props.config.defaultLandingDossierProjectID, this.props.config.defaultLandingDossierID, this.props.config.defaultLandingPageKey)
        }
      }
      else if(dataWWdsf){
        if (isMobile) {
          this.mstrDossierParam(this.props.config.defaultMobileDossierProjectID, this.props.config.defaultMobileDossierID, this.props.config.defaultMobilePageKey)
        } else if (isTablet) {
          this.mstrDossierParam(this.props.config.defaultIpadDossierProjectID, this.props.config.defaultIpadDossierID, this.props.config.defaultIpadPageKey)
        }
        else {
          this.mstrDossierParam(this.props.config.defaultDossierProjectID, this.props.config.defaultDossierID, this.props.config.defaultPageKey)
        }
      }
      else if(dataStorePer){
        if (isMobile) {
          this.mstrDossierParam(this.props.config.defaultLandingMobileDossierProjectID, this.props.config.defaultLandingMobileDossierID, this.props.config.defaultLandingMobilePageKey)
        } else if (isTablet) {
          this.mstrDossierParam(this.props.config.defaultLandingIpadDossierProjectID, this.props.config.defaultLandingIpadDossierID, this.props.config.defaultLandingIpadPageKey)
        }
        else {
          this.mstrDossierParam(this.props.config.defaultLandingDossierProjectID, this.props.config.defaultLandingDossierID, this.props.config.defaultLandingPageKey)
        }
      }
    }

  }

  mstrDossierParam = (projectID, dossierID, pageKEY) => {
    this.selectMSTRDossier({
      projectId: projectID,
      dossierId: dossierID,
      pageKey: pageKEY
    });
  }
  selectMSTRDossier = (params) => {
    const dossier = {
      projectId: params.projectId,
      id: params.dossierId,
      pageKey: params.pageKey
    };

    this.props.setSelectedDossier(dossier, true);
  }

  selectPBIReport = (params) => {
    //removing 's' from the end of object type
    let objectType = params.objectType.substr(0, params.objectType.length - 1);
    //Rubik-700 Issues with Share function
    if (params) {
      if (params.applyRoles) {
        this.props.setSelectedPBIObjectAction(params.workspaceId, params.objectId, objectType, params.pageName, params.applyRoles,params.isFilter);
      } else {
        this.props.setSelectedPBIObject(params.workspaceId, params.objectId, objectType, params.pageName);
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    userInfoDetails: state.userInfoDetails
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setSelectedDossier: setSelectedDossier,
    setSelectedPBIObject: setSelectedPowerBIObjectData,
    setSelectedPBIObjectAction: setSelectedPowerBIObjectDataAction
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App);


