/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import './Search.css'
import { setSelectedDossierId, setExpandedNodes, setSelectedNodes, setSelectedPowerBIObjectData, setSelectedDossierName } from '../../../../actions'
import { dossierService } from '../../../../services/DossierService';


const useStyles = (theme) => ({
    paper: {
        border: "1px solid #5F6A77",
        fontFamily: "Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif !important",
        fontWeight: "520 !important",
        fontSize: "1rem !important",
        backgroundColor: "#161616 !important",
        color: "#5F6A77 !important"
    }
});

/**
 * Search component. It provides ability to search menu tree data
 */
class Search extends Component {

    render = () => {

        const { classes } = this.props;
        return (
            <div>
                <Autocomplete
                    id="size-small-standard"
                    size="small"
                    autoHighlight
                    classes={{ paper: classes.paper }}
                    options={this.props.flatFolderTree}
                    onChange={this.handleSearch}
                    getOptionLabel={this.getName}
                    filterOptions={this.customFilterOptions}
                    onOpen={this.props.onOpen}
                    onClose={this.props.onClose}
                    openOnFocus={true}
                    style={{ width: 300 }}
                    renderOption={(option) => {
                        const name = this.getName(option);
                        return (
                            <React.Fragment>
                                <input type="hidden" name="did" value={option.did ? option.did.trim() : ""} />
                                <input type="hidden" name="type" value={option.type} />
                                <input type="hidden" name="id" value={option.id} />
                                {name}
                            </React.Fragment>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            inputRef={(input) => {
                                if (input !== null && this.props.isSearchFocused) {
                                    setTimeout(function () {
                                        input.focus();
                                    }, 300);
                                }
                            }}
                            {...params}
                            label="Search..."
                            variant="outlined"
                        />
                    )}
                />



            </div>
        );
    }

    getName = (option) => {
        let name = option.name;

        if (option.longDescriptionProps && option.longDescriptionProps.displayName) {
            name = option.longDescriptionProps.displayName;
        }

        return name;
    }

    // Define a separate debounce function
    debounce = (func, delay) => {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func.apply(this, args);
            }, delay);

        };
    };

    // Simulated API function
    callKeySearchAPI = async (inputValue) => {
        // Perform your API call here using the inputValue
        if(inputValue.length>2){
             await dossierService.keySearchAPI(this.props.config, inputValue)
        }
    };

    // Debounced version of the API call
    debouncedAPICall = this.debounce(this.callKeySearchAPI, 2000); // Set the desired debounce delay (e.g., 300 milliseconds)



    customFilterOptions = (options, { inputValue }) => {
        const input = inputValue.trim().toLowerCase();
        if (input) {
            this.debouncedAPICall(input);

        }
        return options.filter(option => {
            const name = this.getName(option).toLowerCase();
            const keySearch = option.longDescriptionProps?.keySearch || [];
            return (
                name.includes(input) ||
                keySearch.some(value => value.toLowerCase().includes(input))
            );
        });
    };


    /**
     * Search handler
     */
    handleSearch = (event, value) => {

        if (!value) return;
        //MSTR/Power BI ID
        let did = value.did ? value.did : null;
        //Report Name
        let reportName = value.longDescriptionProps.displayName ? value.longDescriptionProps.displayName : null;
        //Object type
        let type = value.type ? value.type : null;
        // tree node ID
        let id = value.id ? value.id : null;

        let isPowerBi = value.powerBiWorkspaceId && value.powerBIId && value.powerBIType && value.longDescriptionProps.displayName;
        let toBeHidden = false;

        if (isPowerBi) {
            toBeHidden = true;
            this.props.setSelectedPowerBIObjectData(value.powerBiWorkspaceId, value.powerBIId, value.powerBIType, value.longDescriptionProps.displayName);
        } else if (did) {
            toBeHidden = true;
            this.props.setSelectedDossierId(did);
            this.props.setSelectedDossierName(reportName);
        }

        if (toBeHidden && this.props.parent)
            this.props.parent.hideBar();

        if (id && type) {
            // set default expanded nodes
            // this.populateExpandedNodes(id, parseInt(type));
            const expandedNodes = this.getExpandedNodes(id, parseInt(type))
            this.populateExpandedNodes(expandedNodes);
            //in this case epanded nodes would be also selected nodes as parents of the node selected
            this.props.setSelectedNodes(expandedNodes);
            this.props.setExpandedNodes([...new Set(this.props.expandedNodes)]);
        }
    }

    /**
    * Get parents node id for selected elements to highlight entire path
    */
    getExpandedNodes = (id, type) => {
        return this.getParentIDs(this.props.flatFolderTree, id, type);
    }


    /**
      * TO REMOVE - duplicate from dataService
      */
    getParentIDs = (data, id, type) => {
        const parentIDs = [];

        while (id !== 0) {
            if (!isNaN(id) && !isNaN(type)) {
                const node = this.getNodeByID(data, parseInt(id), parseInt(type));
                if (node && node.parent) {
                    parentIDs.push(node.id.toString());
                    id = node.parent.id;
                    type = node.parent.type
                }
            }
        }

        return parentIDs;
    }

    /**
     * TO REMOVE - duplicate from dataService
     */
    getNodeByID = (data, id, type) => {

        return data.find(element => {
            return element.id === id;
        });
    }

    /**
     * Popuate expanded nodes
     */
    populateExpandedNodes = (expandedNodes) => {
        if (!this.props.expandedNodes || (!expandedNodes || expandedNodes.length === 0)) return;
        //populate expanded nodes (keep an existing ones)
        expandedNodes.forEach(elementID => {
            this.props.expandedNodes.push(elementID);
        });
    }

}

const mapStateToProps = (state) => {
    return {
        expandedNodes: state.expandedNodes,
        flatFolderTree: state.flatFolderTree,
        config: state.config,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setSelectedDossierId: setSelectedDossierId,
        setExpandedNodes: setExpandedNodes,
        setSelectedNodes: setSelectedNodes,
        setSelectedDossierName: setSelectedDossierName,
        setSelectedPowerBIObjectData: setSelectedPowerBIObjectData
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Search));
