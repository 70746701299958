import React, { Component } from 'react'
import Search from '../../Menu/MenuItems/Search/Search'
import MultiSelectTreeView from '../../Menu/MenuItems/TreeView/MultiSelectTreeView';
import CircularProgressIcon from '../../UI/CircularProgressIcon'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onTreeStructureLoad } from '../../../actions'
import LeftBarButtonGroup from '../../Menu/MenuItems/ButtonGroup/LeftBarButtonGroup'
import MenuItemsTitle  from '../../Menu/MenuItems/MenuItemsTtitle/MenuItemsTitle'
import './MobileMenuItems.css';

/**
 * Menu items
 */
export class MobileMenuItems extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchFocus : false
        }
    }

    componentDidUpdate = () => {
       this.isFocused = false;
    }

    componentDidMount = () => {
        if (this.props.folderTree.length === 0)
            this.props.onTreeStructureLoad(this.props.config, this.props.mstrAuthToken);
    }

    render = () => {
    
        let isInitialLoad = this.props.selectedButton === 0 && this.props.folderTree.length === 0;

        return (
            <div>
                {
                    isInitialLoad ? <CircularProgressIcon customStyle={{ marginLeft: "40%", marginTop: "50%" }} /> :
                        (
                            
                            <div className="mobile-itemsContainer">
                   
                                {/* Search box */}
                                <Search data={this.props.folderTree} isFocused={this.isFocused} parent={this} />
                                {/* Menu items title */}
                                <MenuItemsTitle/>
                                {/* Small button groups for favorites etc */}
                                <LeftBarButtonGroup />
                                {/* Tree view */}
                                <MultiSelectTreeView folderTree={this.props.folderTree} parent={this} />
                            </div>
                        )
                }
            </div>
        )
    }

    hideBar() {
        let openbar = this.props.parent;
        
        if(openbar)
            openbar.props.parent.toggleDrawer();
    }

    /**
     * On open handler
     * 
     * @param {*} searchFocus 
     */
    onOpen = (searchFocus) => {
        this.setState({
            searchFocus
        })
        this.isFocused = searchFocus;
        this.props.onOpen()
    }

    /**
     * On close handler
     * 
     * @param {*} searchFocus 
     */
    onClose = (searchFocus) => {
        this.setState({
            searchFocus
        })
        this.isFocused = searchFocus;
        this.props.onClose()
    }

    /**
     * Check  if node has children.
     * 
     * @param {*} node 
     * @returns 
     */
    hasChildren = (folder) => {
        return folder.children && folder.children.length > 0
    }
}

const mapStateToProps = (state) => {
    return {
        folderTree: state.folderTree,
        mstrAuthToken: state.mstrAuthToken,
        config: state.config
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onTreeStructureLoad: onTreeStructureLoad,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuItems);
