import React, { Component } from 'react';
import './ImageGallary.css';
import { favoritesService } from '../../services/FavoritesService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ImageGallaryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            base64Data: {},
            srcImages: [],
            title: '',
            description: ''

        };
    }

    handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        const imageFiles = this.filterImageFiles(files);
        if (imageFiles.length > 0) {
            const file = imageFiles[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const image = { name: file.name, src: reader.result };
                this.setState({ image });
            };
        }
    };

    handleDragOver = (e) => {
        e.preventDefault();
    }

    handleFileChange = (e) => {
        const files = e.target.files;
        const imageFiles = this.filterImageFiles(files);
        if (imageFiles.length > 0) {
            const file = imageFiles[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const image = { name: file.name, src: reader.result };
                this.setState({ image });
            };
        }
    };

   
    handleCancelClick = () => {
        this.setState({ image: '' });
    }

    handleTitleChange = (e) => {
        const title = e.target.value;
        this.setState({ title });
    };

    handleDescriptionChange = (e) => {
        const description = e.target.value;
        this.setState({ description });
    };



    handleSubmit = (e) => {
        // e.preventDefault()
        // const { image, title, description } = this.state;
        // const encodedData = btoa(image.src)

        // const newData = {
        //     title: title,
        //     description: description,
        //     image: encodedData,
        // };

        // console.log('InputForm data:', newData);

        // favoritesService.snowflakeAdd(this.props.config.mstrLibraryUrl,newData)
    };



    filterImageFiles = (files) => {
        const imageTypes = ['image/png', 'image/jpeg', 'image/gif'];
        const imageFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (imageTypes.includes(file.type)) {
                imageFiles.push(file);
            }
        }
        return imageFiles;
    };



    getImages = (e) => {
        console.log("Image Data: ", this.state.images)

        let imageFiles = [];
        const images = [...this.state.images];
        imageFiles = images.map(map => map.src)
        console.log("imageFiles Data: ", imageFiles)
        const encodedBase64Strings = encodeURIComponent(imageFiles.join(","));

        console.log("encodedBase64Strings: ", encodedBase64Strings)
    }

    render() {
        const { images, title, description } = this.state;
        return (
            <div className='formbold-main-wrapper'>
                <form onSubmit={this.handleSubmit}>
                    <div className="formbold-form-wrapper">


                        <div className="formbold-mb-5">
                            <label htmlFor="email" className="formbold-form-label">
                                Title:
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your Title"
                                className="formbold-form-input"
                                value={title}
                                onChange={this.handleTitleChange}

                            />
                        </div>

                        <div className="formbold-mb-5">
                            <label htmlFor="email" className="formbold-form-label">
                                Description:
                            </label>
                            <textarea
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your Description"
                                className="formbold-form-textarea"
                                value={description}
                                onChange={this.handleDescriptionChange}

                            />
                        </div>


                        <div className="image-drop-zone formbold-mb-5" onDrop={this.handleDrop} onDragOver={this.handleDragOver}>
                            <p>Drag and drop images here or click to browse</p>
                            <input type="file" accept="image/png,image/jpeg,image/gif" name="image" onChange={this.handleFileChange} />
                        </div>

                        <div>
                            <button className="formbold-btn w-full" type="submit">Send File</button>
                        </div>
                    </div>
                </form>

                <div className="image-preview-container">
                    {images.map((image, index) => (
                        <div className="image-preview" key={index}>
                            <img src={image.src} alt={image.name} />
                            <div className="image-preview-name">{image.name}</div>
                            <button onClick={() => this.handleCancelClick(index)}>Cancel</button>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
       
        config: state.config
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageGallaryForm);
