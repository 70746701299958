import React, { Component } from 'react'
import './MenuItemsTitle.css'
import { connect } from 'react-redux';

/**
 * Navigation items title
 */
export class MenuItemsTitle extends Component {
    render =() => {
        return (
            <div className="menuItemsTitle">
               {this.props.config.navPanelTitle}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        config: state.config
    }
}

export default connect(mapStateToProps, null)(MenuItemsTitle);
