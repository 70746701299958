import React, { Component } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import ImageCard from "./ImageCard";
import "./ImageGallary.css";
import { instagramResponse } from "./instagramResponse";

export class ImageGallary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paramData: "",
      dialog: false,
    };
  }

  handleParam = (data) => {
    this.setState({ paramData: data, dialog: true });
  };

  closeLightbox = (data) => {
    this.setState({ paramData: 0, dialog: false });
  };

  render() {
    return (
      <div className="image-gallary-layout">
        {instagramResponse.map((IGData) => {
          return (
            <ImageCard
              instagramResponse={IGData}
              handleParam={this.handleParam}
            />
          );
        })}
        {/* <ImageDetails paramData={this.state.paramData} /> */}
        {/* <DummyGallary /> */}

        <ModalGateway>
          {this.state.dialog ? (
            <Modal onClose={this.closeLightbox}>
              <Carousel
                currentIndex={this.state.paramData}
                views={instagramResponse.map((x) => ({
                  // source: {
                  //   download: x.src,
                  //   fullscreen: x.src,
                  //   regular: x.src,
                  //   thumbnail: x.src
                  // },
                  // srcset: x.caption,
                  // caption: x.caption,
                  ...x,
                  srcset: x.caption,
                  caption: x.caption,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  }
}

export default ImageGallary;
