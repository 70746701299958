import { Component } from 'react';
import './Frame.css';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { Button, MobileStepper } from '@material-ui/core';
import Swipeable from '../../utils/Swipeable';

const SWIPE_DIRECTIONS = {
    LEFT: 0,
    RIGHT: 1
}

const initialState = {
    currentIndex: 0,
    pages: []
}

class MobilePageNavigation extends Component {

    constructor(props) {
        super(props);

        this.dossier = null;
        this.pbiReport = null;

        this.state = initialState;
    }

    setDossier = (dossier) => {
        this.setState(initialState);
        
        this.pbiReport = null;
        this.dossier = dossier;

        let pages = [];

        this.dossier.getChapterList().forEach(chapter => pages = pages.concat(chapter.getPageList()));

        this.setState({
            pages: pages
        });

        this.updateCurrentPosition();
    }

    setPBIReport = async (report) => {
        this.setState(initialState);

        this.dossier = null;
        this.pbiReport = report;

        let pages = [];

        pages = await report.getPages();

        this.setState({
            pages: pages
        });

        this.updateCurrentPosition();
    }

    render() {
        if (this.state.pages.length <= 1) {
            return null;
        }

        return <Swipeable
            onSwipedLeft={() => this.swipe(SWIPE_DIRECTIONS.LEFT)}
            onSwipedRight={() => this.swipe(SWIPE_DIRECTIONS.RIGHT)}
        >
            <MobileStepper
                variant="dots"
                steps={this.state.pages.length}
                position="static"
                activeStep={this.state.currentIndex}
                sx={{ maxWidth: "100%", flexGrow: 1, justifyContent: "center" }}
                nextButton={
                    <Button size="small" onClick={this.nextPage} disabled={this.state.currentIndex === this.state.pages.length - 1}>
                        <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={this.prevPage} disabled={this.state.currentIndex === 0}>
                        <KeyboardArrowLeft />
                    </Button>
                }
            />
        </Swipeable>
    }

    reset = () => {
        this.setState(initialState);
    }

    swipe = (direction) => {
        switch (direction) {
            case SWIPE_DIRECTIONS.LEFT:
                if (this.state.currentIndex < this.state.pages.length - 1){
                    this.nextPage();
                }
                break;
            case SWIPE_DIRECTIONS.RIGHT:
                if (this.state.currentIndex > 0){
                    this.prevPage();
                }
                break;
            default:
                break;
        }
    }

    nextPage = async () => {
        if (this.dossier){
            await this.dossier.goToNextPage();
        }
        else if (this.pbiReport){
            const newPage = this.state.pages[this.state.currentIndex+1];
            await this.pbiReport.setPage(newPage.name);
        }

        this.updateCurrentPosition();
    }

    prevPage = async () => {
        if (this.dossier){
            await this.dossier.goToPrevPage();
        }
        else if (this.pbiReport){
            const newPage = this.state.pages[this.state.currentIndex-1];
            await this.pbiReport.setPage(newPage.name);
        }

        this.updateCurrentPosition();
    }

    updateCurrentPosition = () => {
        if (this.dossier) {
            this.updateMSTRPosition();
        }
        else if (this.pbiReport) {
            this.updatePBIPosition();
        }        
    }

    updateMSTRPosition = () => {
        const currentPageKey = this.dossier.getCurrentPage().nodeKey;
        const index = this.state.pages.findIndex(page => page.nodeKey === currentPageKey);

        this.setState({
            currentIndex: index
        });
    }

    updatePBIPosition = async () => {
        const pages = await this.pbiReport.getPages();

        const index = pages.findIndex(page => page.isActive);

        this.setState({
            pages: pages,
            currentIndex: index
        });
    }
}

export default MobilePageNavigation;