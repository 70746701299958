import React from 'react';
import logo from '../../images/ProgressLogo.svg'

const useStyles = {
  display: 'flex',
  position:'absolute',
  'zIndex': 100,
  height: '100vh',
  width: '100%',
  'alignItems': 'center',
  'justifyContent': 'center',
  'backgroundColor': '#F5F5F5'
}


export default function CircularIndeterminate() {


  return (
    <div className="loader-line"></div>
  );
}