class RedirectionHelper {
    redirectToTargetLocation = () => {
        const targetLocation = this.getTargetLocationFromCookie();

        //we have to clear the cookie regardless of further logic to not impact any future actions
        this.clearTargetLocationCookie();

        if (targetLocation && (document.location.href !== targetLocation)) {
            console.log("Redirecting to target location: " + targetLocation);
            window.location.href = targetLocation;
        }
    }

    clearTargetLocationCookie = () => {
        document.cookie = "targetLocation=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/rubik;";
    }

    getTargetLocationFromCookie = () => {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];

            const cookieName = cookie.split("=")[0];
            const cookieValue = cookie.split("=")[1];

            if (cookieName === "targetLocation") {
                return cookieValue;
            }
        }

        return null;
    }

    setTargetLocationCookie = () => {
        const redirectionUrl = this.getTargetLocationFromCookie();
        if (!redirectionUrl) {
            console.log("Setting target location cookie: " + document.location.href);
            document.cookie = `targetLocation=${document.location.href}; max-age=${3600*24}; path=/rubik`;
        }
    }
}

export const redirectionHelper = new RedirectionHelper();