import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { powerBIService } from '../../services/PowerBIService';
import { onError, setAuthToken, setEmbeddedDossierStatus, setPowerBIToken, turnOffPowerBI, showProgress, hideProgress, showAlert } from '../../actions'
import ERRORS from '../../errorTypes'
import './Frame.css';
import MobilePageNavigation from './MobilePageNavigation';
import React from 'react';
import { EMBED_OBJECT_TYPES } from '../../utils/Constants';
import { deviceHelper } from '../../utils/DeviceHelper';
import customError from '../../customError'
import { authenticationService } from '../../services/AuthenticationService';
import AlertMobile from "../UI/AlertMobile"
import AlertDesktop from '../UI/AlertDesktop';
import DashboardAlert from '../UI/DashboardAlert';
import { dossierService } from '../../services/DossierService';

const OBJECT_TYPES = {
    NONE: 0,
    MSTR: 1,
    PBI: 2
}

const MESSAGES = {
    LOGOUT: "logout",
}


/**
 * Embedded iframe. This is placeholder component for MicroStrategy embedded API
 */
class EmbeddedDossier extends Component {

    constructor(props) {
        super(props);

        this.mobileNavigation = React.createRef();
        this.embeddedDossier = React.createRef();

        this.dossier = null;
        this.pbiReport = null;

        this.dossierInstance = null;
        this.interval = null;
    }

    componentDidUpdate = () => {
        this.embedSelectedObject();
    }

    componentDidMount = () => {
        this.embedSelectedObject();
    }

    
    shouldComponentUpdate = (nextProps) => {
        //this component should update if:
        //1. selected dossier has changed - embeddedDossierStatus will be null
        //2. selected dossier has expired (refresh) - embeddedDossierStatus will be 0
        if (nextProps.embeddedDossierStatus) {
            return false;
        }
        else return true;
    }

    render = () => {
        const isMobile = deviceHelper.isMobile();
        let isTablet = deviceHelper.isTablet();
        isTablet = isTablet ? true : false;
        return <>
            {(isMobile || isTablet) ? <AlertMobile /> : <AlertDesktop />}
            <div id="dossierFrame">
                <div id="dossierContainer" ref={this.embeddedDossier}></div>
                <DashboardAlert />
            </div>
            {/* <button onClick={()=> this.logout()}>logout</button> */}
            {(isMobile || isTablet) && <MobilePageNavigation ref={this.mobileNavigation} />}
        </>
    }

    embedSelectedObject = () => {
        this.stopPollingStatus();
        this.props.showProgress();
        const objectType = this.getObjectType();

        switch (objectType) {
            case OBJECT_TYPES.PBI:
                this.embedPowerBI();
                break;
            case OBJECT_TYPES.MSTR:
                this.embedMSTR();
                break;
            default:
                break;
        }
    }

    getObjectType = () => {
        if (!this.props.selectedObject) {
            return OBJECT_TYPES.NONE;
        }
        else if (this.props.selectedObject.type === EMBED_OBJECT_TYPES.PBI_REPORT || this.props.selectedObject.type === EMBED_OBJECT_TYPES.PBI_DASHBOARD) {
            return OBJECT_TYPES.PBI;
        }
        else return OBJECT_TYPES.MSTR;
    }

    awaitLibraryMessages = () => {
        window.addEventListener("message", (event) => {
            switch (event.data) {
                case MESSAGES.LOGOUT:
                    this.onLogout();
                    break;
                default:
                    break;
            }
        })
    }

    onLogout = () => {
        this.props.setAuthToken(null);
    }

    logout = () => {

    }

    setErrorDeviceView = (deviceType) => {
        let errorMessage = "This report is designed to be viewed on " + deviceType + ", please access the report on Rubik " + deviceType;
        this.props.onError({
            code: ERRORS.DISABLE_REFRESH.CODE,
            title: "Are you with the wrong device?",
            message: errorMessage
        })
    }

    embedPowerBI = async () => {

        const mobileNavigation = this.mobileNavigation.current;

        mobileNavigation && mobileNavigation.reset();

        const isMobile = deviceHelper.isMobile();

        if (window.location.hash) {
            if (isMobile) {
                if (window.location.hash !== "#mobile") {
                    this.setErrorDeviceView("Desktop")
                }
            } else {
                if (window.location.hash !== "#desktop") {
                    this.setErrorDeviceView("Mobile")
                }
            }
        }


        if (this.props.config.enablePowerBI && this.props.enablePowerBI) {
            const selectedObject = this.props.selectedObject;

            const workspaceId = selectedObject.projectId;
            const objectId = selectedObject.objectId;
            const objectType = selectedObject.type;
            const pageName = selectedObject.pageName;
            const applyRoles = selectedObject.applyRoles;
            const reportName = selectedObject.reportName

            const isFilter = selectedObject.isFilter

            const userTrustId = this.props.user.trustId;

            const placeholder = this.getEmbedPlaceholder();

            
        const baseURL = '/rubik/embed/pbi';
        const PBIURL = `${baseURL}/${workspaceId}/${objectType}s/${objectId}`;
        window.history.pushState({}, '', PBIURL);


            try {
                const identityToken = await authenticationService.getIdentityToken(this.props.config, this.props.mstrAuthToken);

                const getNewPowerBIToken = await powerBIService.getAccessTokenAppOwnsData(this.props.config, identityToken);

                const report = await powerBIService.embed(workspaceId, objectId, objectType, pageName, getNewPowerBIToken, this.props.config, placeholder, applyRoles, userTrustId, this.props.mstrAuthToken,isFilter)

                this.props.hideProgress();

                mobileNavigation && mobileNavigation.setPBIReport(report);

                this.props.onEmbedPBIReport(report);

                //Rubik-718
                powerBIService.setAnalyticsPBI(this.props.config, this.props.userInfoDetails["trustId"], navigator.userAgent, reportName)

            }
            catch (error) {
                if (error.message === "Invalid access token") {
                    //try to refresh the access token
                    powerBIService.getAccessTokenAppOwnsData(this.props.config, this.props.identityToken);
                }
                else this.props.onError({
                    code: ERRORS.ENDPOINT_ERROR.CODE,
                    title: "Error",
                    message: error.message
                });
            }
        }
        else {
            this.props.onError({
                code: ERRORS.POWER_BI_TURNED_OFF.CODE,
                title: ERRORS.POWER_BI_TURNED_OFF.DESC,
                message: "PowerBI is currently not available."
            });
        }
    }

    /**
     * Show dossier via embedded api
     */
    embedMSTR = async () => {
        const mobileNavigation = this.mobileNavigation.current;

        mobileNavigation && mobileNavigation.reset();

        const config = this.props.config;
        let placeholderDiv = this.getEmbedPlaceholder();

        //reset power bi container 
        window.powerbi.reset(placeholderDiv);
        const me = this;

        const selectedDossier = this.props.selectedObject;
        let pageMenu = false
        let navigationBarFlag = true
        if (selectedDossier.pageMenu) {
            pageMenu = true
        }
        const isMobile = deviceHelper.isMobile();

        if (window.location.hash) {
            if (isMobile) {
                if (window.location.hash !== "#mobile") {
                    this.setErrorDeviceView("Desktop")
                }
            } else {
                if (window.location.hash !== "#desktop") {
                    this.setErrorDeviceView("Mobile")
                }
            }
        }
        let projectID = selectedDossier.projectId || config.projectID;

        if (config.defaultDossierID === selectedDossier.objectId)
            projectID = config.defaultDossierProjectID;

        let dossierUrl = `${config.mstrLibraryUrl}/app/${projectID}/${selectedDossier.objectId}`;

        if (selectedDossier.pageName) {
            dossierUrl += `/${selectedDossier.pageName}`;
        }

        if (isMobile) {
            navigationBarFlag = false
            if (pageMenu) {
                navigationBarFlag = true
            }
        }

        await dossierService.publishDocument(config, this.props.mstrAuthToken, projectID, selectedDossier.objectId, this.props.user["id"])

        const navigationBar = {
            enabled: navigationBarFlag,
            gotoLibrary: false,
            title: pageMenu,
            toc: true,
            reset: true,
            reprompt: true,
            share: true,
            comment: false,
            notification: false,
            filter: true,
            options: false,
            search: false,
            bookmark: true
        }

        const dossier = await window.microstrategy.dossier.create({
            placeholder: placeholderDiv,
            url: dossierUrl,
            enableResponsive: true,
            errorHandler: me.customErrorHandler,
            navigationBar: navigationBar
        })

        //     // Update the URL to reflect the dossier URL
        // const urlParams = new URLSearchParams(window.location.search);
        // urlParams.set('dossierUrl', dossierUrl);
        // window.history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`);

            // Concatenate the parts to form the complete URL

        const baseURL = '/rubik/embed/mstr/';
        const mstrURL = `${baseURL}${projectID}/${selectedDossier.objectId}`;
        window.history.pushState({}, '', mstrURL);


        this.props.hideProgress();

        dossier.addCustomErrorHandler(this.customErrorHandler);

        const instanceId = await dossier.getDossierInstanceId();

        this.dossierInstance = {
            ...selectedDossier,
            projectId: projectID,
            instanceId: instanceId
        }
        this.startPollingStatus();
        //this.props.setEmbeddedDossierStatus(1);

        mobileNavigation && mobileNavigation.setDossier(dossier);

        this.props.onEmbedMSTRDossier(dossier);

        this.awaitLibraryMessages();
    }

    getEmbedPlaceholder = () => {
        return document.getElementById("dossierContainer");
    }


    /**
     * Custom error handler for the embedded api.
     * 
     * @param {*} error 
     */
    customErrorHandler = (error) => {
        console.error(error);
        let CustomMessage = customError(error)
        let errorTitle = "";
        let errorMessage = "";
        if (CustomMessage) {
            errorTitle = CustomMessage.errorTitle;
            errorMessage = CustomMessage.errorMessage;
        } else {
            errorTitle = "Error"
            errorMessage = error.message;
        }
        if (error) {
            if (!error["desc"].match("Please review your answers")) {
                this.props.onError({
                    code: ERRORS.DOSSIER_ERROR.CODE,
                    title: errorTitle,
                    message: errorMessage
                })
            }
        }
    }

    startPollingStatus = async () => {
        this.interval = window.setTimeout(this.pollStatus, 10000);
    }

    pollStatus = async () => {
        if (!this.dossierInstance) {
            this.stopPollingStatus();
            return;
        }

        // const projectId = this.dossierInstance.projectId;
        // const dossierId = this.dossierInstance.objectId;
        // const instanceId = this.dossierInstance.instanceId;

        // const status = await dossierService.getStatus(projectId, dossierId, instanceId, this.props.config, auth);

        // if (status == 0) {
        //     this.onDossierExpire();
        // }
    }

    stopPollingStatus = () => {
        this.interval && window.clearInterval(this.interval);
    }

    onDossierExpire = () => {
        this.stopPollingStatus();
        this.props.onError({
            code: ERRORS.DOSSIER_ERROR.CODE,
            title: "Dossier has expired",
            message: "The dossier has expired - please refresh it."
        });
    }
}

const mapStateToProps = (state) => {
    return {
        mstrAuthToken: state.mstrAuthToken,
        identityToken: state.identityToken,
        alert: state.alert,
        userInfoDetails: state.userInfoDetails,
        powerBIToken: state.powerBIToken,
        config: state.config,
        selectedObject: state.selectedObject,
        enablePowerBI: state.enablePowerBI,
        embedURL: state.selectedObject.embedUrl,
        user: state.user,
        authToken: state.mstrAuthToken,
        embeddedDossierStatus: state.embeddedDossierStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onError: onError,
        setPowerBIToken: setPowerBIToken,
        turnOffPowerBI: turnOffPowerBI,
        showAlert: showAlert,
        setAuthToken: setAuthToken,
        setEmbeddedDossierStatus: setEmbeddedDossierStatus,
        showProgress: showProgress,
        hideProgress: hideProgress,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EmbeddedDossier);


