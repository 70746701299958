import AbstractService from './AbstractService';
import { deviceHelper } from '../utils/DeviceHelper';
/**
 * Folder service to retrieve folder form MicroStrategy
 */
class PowerBIService extends AbstractService {

    embed = async (workspaceId, objectId, objectType, pageName, accessToken, config, placeholder, applyRoles, userTrustId, identityToken, isFilter) => {
        return new Promise(async (resolve, reject) => {
            try {
                const embedConfig = await this.getEmbedConfiguration(workspaceId, objectId, objectType, accessToken, config);

                const datasetId = embedConfig.datasetId;

                const embedToken = await this.getEmbedToken(datasetId, objectId, accessToken, config, applyRoles, userTrustId);

                const reportLoadConfig = this.getReportLoadConfig(embedConfig.embedUrl, objectType, pageName, embedToken, isFilter);

                window.powerbi.reset(placeholder);
                const report = window.powerbi.embed(placeholder, reportLoadConfig);

                if (window.screen && window.screen.orientation){
                    window.screen.orientation.onchange = () => {
                        this.updateOrientation(report, reportLoadConfig);
                    }
                }
                else if (window.orientation){
                    window.onorientationchange = () => {
                        this.updateOrientation(report);
                    }
                }

                report.on("rendered", () => {
                    resolve(report);
                });
            } catch (error) {
                console.log(error);                
                reject(error);
            }        
        });
    }

    updateOrientation = (report) => {    
        const orientation = deviceHelper.getOrientation();

        const newSettings = {
            panes: {
                filters: {
                    visible: true
                },
                pageNavigation: {
                    visible: true
                }
            },
            layoutType: orientation
        };
        
        report.updateSettings(newSettings);        
    }
    
    //Rubik-718
    setAnalyticsPBI = async (config, userId, userAgent, reportName) =>{
        console.log("setAnalyticsPBI => ",userId, userAgent, reportName)
        let operation = "ViewReport";
        let reportType = "PBI";
        let taskUrlPBI = `${config.mstrLibraryUrl}/servlet/taskProc?taskId=migrateToSnowflakeTask&taskEnv=xml&taskContentType=xml&userId=${userId}&userAgent=${userAgent}&operation=${operation}&reportName=${reportName}&reportType=${reportType}`;

        return this.sendRequest(taskUrlPBI, "GET", {
            'Content-Type': 'application/json',
            'accept':"application/json",
            'Access-Control-Allow-Origin':'*'
        }, {});
    }

    getAccessTokenAppOwnsData = async (config, identityToken) => {
        let taskUrl = `${config.mstrLibraryUrl}/servlet/taskProc?taskId=authenticateToAzureADTask&taskEnv=xhr&taskContentType=json&identityToken=${identityToken}`;

        const res = await this.sendRequest(taskUrl, "GET", {
            'Content-Type': 'application/json',
            'accept': "application/json",
            'Access-Control-Allow-Origin': '*'
        }, {});

        let accessToken = null;

        if (res.ok){
            const resBody = await res.json();
            accessToken = resBody.accessToken;
        }

        return accessToken;
    }

    getEmbedConfiguration = async (workspaceId, objectId, objectType, accessToken, config) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${accessToken}`);
        
        var options = {
            method: 'GET',
            headers: headers
        };

        const url = `${config.powerBIAPIURL}/groups/${workspaceId}/${objectType}s/${objectId}`;
        console.log(url)

        const res = await fetch(url, options);

        if (res.ok){
            const embedConfig = await res.json();
            return embedConfig;
        }
        else throw new Error("Invalid access token");
    }

    getEmbedToken = async (datasetId, objectId, accessToken, config, applyRoles, userTrustId) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${accessToken}`);

        let body = {
            datasets : [{
                id : datasetId
            }],
            reports : [{
                id : objectId
            }]
        };

        if(applyRoles){
            body = {
                ...body,
                identities: [
                    {
                        roles: applyRoles,
                        datasets: [ datasetId ],
                        userName: userTrustId
                    }
                ]
            }
        }
        
        var options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        };

        const url = `${config.powerBIAPIURL}/GenerateToken`;

        const res = await fetch(url, options);

        if (res.ok){
            const embedToken = await res.json();
            return embedToken.token;
        }
        else throw new Error("Invalid access token");

    }

    getReportLoadConfig = (embedUrl, objectType, pageName, token, isFilter) => {
        const models = window['powerbi-client'].models;
        
        const orientation = deviceHelper.getOrientation();

        const isMobile = deviceHelper.isMobile();
        
        let layoutOrientation = isMobile ? models.LayoutType.MobilePortrait : orientation

        if(isFilter == undefined){
            isFilter = true
        }
        // RUBIK-757 Hide Filter in PBI reports
        const config = {
            type: objectType,
            tokenType: models.TokenType.Embed,
            accessToken: token,
            embedUrl: embedUrl,
            pageName: pageName,
            permissions:  models.Permissions.All,
            settings: {
                persistentFiltersEnabled: true,
                layoutType: layoutOrientation,
                panes: {
                    filters: {
                        visible: isFilter
                    },
                    pageNavigation: {
                        visible: !isMobile
                    }
                },
                bars: {
                    actionBar: {
                       visible: true
                    }
                 }
            }
        };

        return config;
    }
}


export const powerBIService = new PowerBIService();