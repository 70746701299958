import React, { Component } from 'react'
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import Logo from '../UI/Logo'
import { withStyles } from '@material-ui/core/styles';
import DashboardStyle from '../Dashboard/DashboardStyle';
import CssBaseline from '@material-ui/core/CssBaseline';
import './Menu.css'
import MenuItems from './MenuItems/MenuItems'
import { connect } from 'react-redux';
import BottomButtons from './MenuItems/BottomButtons/BottomButtons';
import { deviceHelper } from '../../utils/DeviceHelper';

/**
 * Left  panel menu
 */
export class Menu extends Component {

    /**
     * Constructor.
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        }
    }

    /**
     * Open left panel handler
     */
    handleDrawerOpen = () => {
        this.setState({
            open: true
        })
    };

    /**
     * Close left panel handler
     */
    handleDrawerClose = () => {
        this.setState({
            open: false
        })
    };


    render = () => {
  
        const classes = this.props.classes;
        return (
            <div>
                <CssBaseline />
                <Drawer
                    id="sideMenu"
                    style={{ height: '100%', overflowY: "auto" }}
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper + "menu", !this.state.open && classes.drawerPaperClose)
                    }}
                    open={!!this.state.open}
                >
                    {/* Menu container */}
                    <div className={classes.toolbarIcon} >
                        {/* App logo for left panel menu */}
                        <Logo size={"40"} /> 
                        {/* App name from config */}
                        <div className={this.state.open && "appTitle"}>{this.state.open && this.props.config.appName}</div>
                    </div>
                    <Divider />
                    {/* Menu items */}
                    <MenuItems onShare={this.props.onShare} isFocused={false} isOpen={this.state.open} onOpen={this.handleDrawerOpen} onClose={this.handleDrawerClose} />
                    <Divider />
                    {!deviceHelper.isMobile() && <BottomButtons open={this.state.open} config={this.props.config}/>}
                </Drawer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.config
    }
}


export default connect(mapStateToProps, null)(withStyles(DashboardStyle)(Menu));
