import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./ImageGallary.css";

export class ImageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentID: "",
    };
  }

  handleParamResponse = (id) => {
    this.props.handleParam(id)
  }

  render() {
    // console.log("this.props.url ", this.props.instagramResponse.download_url);
    return (
      // <Link to={`/rubik/ImageGallary/${this.props.instagramResponse.id}`}>
        <div className="image-card" onClick={()=>this.handleParamResponse(this.props.instagramResponse.id)}>
          <img
            src={this.props.instagramResponse.src}
            alt="full_name"
            className="image"
            onClick={() =>
              this.setState({ studentID: this.props.instagramResponse.id })
            }
          />
        </div>
      // </Link>
    );
  }
}

export default ImageCard;
