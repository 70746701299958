import React, { Component } from 'react'
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';

import Logo from '../../UI/Logo';

import CssBaseline from '@material-ui/core/CssBaseline';
import './Openbar.css';
import MobileMenuItems from '../MobileMenuItems/MobileMenuItems';
import { connect } from 'react-redux';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';

/**
 * Left  panel menu
 */
export class Openbar extends Component {

   
    render = () => {

        return (
            <div >
                <CssBaseline />
                <Drawer
            
                    variant="permanent"
                    className="openbar-drawer"
                    open={true}
                    anchor={"top"}  >
                    {/* Menu container */}
                    <div  >
                        {/* App logo for left panel menu  size={"40"} */}
                        <Logo size={"40"} />
                        {/* App name from config */}
                        <div className={"mobile-appTitle"}>{this.props.config.appName}</div>

                        <IconButton className={"mobile-openbar"} onClick={this.props.parent.toggleDrawer}>
                            <ChevronLeftIcon className="sideBarIcon" />
                        </IconButton>
                    </div>
                    <Divider />
                    {/* Menu items */}
                    <MobileMenuItems isFocused={false} isOpen={true} parent={this}  />
                </Drawer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
   
    return {
        config: state.config
    }
}


export default connect(mapStateToProps, null)(Openbar);
