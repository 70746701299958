import React, { Component } from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import '../SideBarTitle/SideBarTitle.css'

/**
 * Small list icons for the left menu collapsed
 */
class SmallList extends Component {
    render = () => {
        return (
            <div>
                <List>
                    <ListItem button>
                        <ListItemIcon>
                            <MenuIcon className="sideBarIcon" onClick={() => {
                                this.props.onOpen(false);
                            }} />
                        </ListItemIcon>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <SearchIcon className="sideBarIconDisabled" onClick={() => {
                                this.props.onOpen(true);
                            }} />
                        </ListItemIcon>
                    </ListItem>
                </List>
            </div>
        )
    }
}
export default SmallList;
