import AbstractService from './AbstractService'

/**
 * Folder service to retrieve folder form MicroStrategy
 */
class FolderService extends AbstractService {
    /**
     * Constructor.
     * 
     * @param {*} props 
     */
    constructor(props) {
        super();
    }
    /**
     * Fetch folders from MicroStategy metadata for default poject
     * @rootFolder MicroStrategy root folder for the tree view
     */
    fetchFolders = (config, identityToken) => {
        let fetchFolderURL = `${config.mstrLibraryUrl}/servlet/taskProc?taskId=getFolderStructureTask&taskEnv=xhr&taskContentType=json&rootFolderID=${config.rootFolderID}&identityToken=${identityToken}&projectID=${config.projectID}`;
        
        return this.sendRequest(fetchFolderURL, "GET", {
            'Content-Type': 'application/json',
            'accept':"application/json",
            'Access-Control-Allow-Origin':'*'
        }, {})
    }
}


export const folderService = new FolderService();