import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAuthToken, onError, setUserDetails } from '../actions';
import { authenticationService } from '../services/AuthenticationService';
import { redirectionHelper } from './RedirectionHelper';
import errors from '../errorTypes';

class AuthenticatedByMSTR extends Component {

    render(){
        //render children only if MSTR session is established
        return this.props.authToken ? this.props.children : null;
    }

    componentDidMount(){
        this.validateSession();
    }

    //this component should not update unless authToken is changed, which should only happen at log off
    componentDidUpdate(){
        if (this.props.authToken === null){
            this.validateSession();
        }
    }

    validateSession = async () => {
        const config = this.props.config;

        const authToken =  await authenticationService.getAuthToken(config);

        if (authToken && await authenticationService.isSessionAlive(config, authToken)) {
            this.onAuthenticated(config, authToken);
        }
        else {
            //there is no session - we need to redirect to MSTR Library
            this.redirectToLoginPage();
        }
    }

    redirectToLoginPage = () => {
        redirectionHelper.setTargetLocationCookie();

        const loginPageURL = `${this.props.config.mstrLibraryUrl}/`;
        window.location = loginPageURL;
    }

    onAuthenticated = async (config, authToken) => {
        //this method will verify if there's a target location set in cookie and redirect if needed
        redirectionHelper.redirectToTargetLocation();
        //RUBIK-643 Whitescreen issue on Rubik Mobile App
        try {
            if (this.props.authToken !== authToken) {
                const identityToken = await authenticationService.getIdentityToken(this.props.config, authToken);

                const userInfo = await authenticationService.getUserInfo(config, authToken);
                const userDef = userInfo && await authenticationService.getUser(userInfo.id, config, authToken);

                let user = null;

                if (userDef) {
                    user = {
                        id: userDef.id,
                        trustId: userDef.trustId
                    }
                    this.props.setUserDetails(userDef)
                }
                this.props.setAuthToken(authToken, identityToken, user);
            }
        }
        catch (error) {
            console.log(error);
            this.props.onError({
                    code: errors.SESSION_EXPIRE.CODE,
                    title: "Welcome back!",
                    message: "Your session was timed out for security reasons. Simply hit refresh or restart the app to view your reports again."
                });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.config,
        authToken: state.mstrAuthToken
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onError: onError,
        setAuthToken: setAuthToken,
        setUserDetails:setUserDetails
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedByMSTR);