import React, { Component } from 'react'
import Search from './Search/Search'
import MultiSelectTreeView from './TreeView/MultiSelectTreeView';
import CircularProgressIcon from '../../UI/CircularProgressIcon'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SmallList from './SmallList/SmallList'
import { onTreeStructureLoad } from '../../../actions'
import LeftBarButtonGroup from './ButtonGroup/LeftBarButtonGroup'
import MenuItemsTitle  from './MenuItemsTtitle/MenuItemsTitle'
import SideBarTitle from './SideBarTitle/SideBarTitle'

/**
 * Menu items
 */
export class MenuItems extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isSearchFocused : false
        }
    }

    componentDidMount = () => {
   
        if (this.props.folderTree.length === 0)
            this.props.onTreeStructureLoad(this.props.config, this.props.mstrAuthToken);
    }

    render = () => {

        let isInitialLoad = this.props.selectedButton === 0 && this.props.folderTree.length === 0;

        const style = {
            flexGrow: 2,
            overflowY: 'auto',
            overflowX: 'hidden'
        }
       
        return (
            <div style = {style}>
                {
                    isInitialLoad ? <CircularProgressIcon customStyle={{ marginLeft: "34%", marginTop: "50%" }} /> :
                        (this.props.isOpen ?
                            (
                                <div style = {{width: 'inherit'}}>
                                    {/* Side bar title */}
                                    <SideBarTitle onClose = {this.onClose} onOpen = {this.onOpen} isOpen = {this.props.isOpen}/>
                                    {/* Search box */}
                                    <Search data={this.props.folderTree} isSearchFocused={this.state.isSearchFocused} onOpen={this.onSearchOpen} onClose={this.onSearchClose}  />
                                    {/* Menu items title */}
                                    <MenuItemsTitle/>
                                    {/* Small button groups for favorites etc */}
                                    <LeftBarButtonGroup />
                                    {/* Tree view */}
                                    {/* <GmailTreeView/> */}
                                    <MultiSelectTreeView onShare={this.props.onShare} folderTree={this.props.folderTree}/>
                                </div>
                            ) :
                            <SmallList isOpen={this.props.isOpen} onOpen={this.onOpen} onClose ={this.onClose} />
                        )
                }
            </div>
        )
    }

    /**
     * On open handler
     * 
     * @param {*} searchFocus 
     */
    onOpen = (searchFocus) => {

        this.setState({
            isSearchFocused : searchFocus
        })
        this.props.onOpen()
    }

    /**
     * On close handler
     * 
     * @param {*} searchFocus 
     */
    onClose = (searchFocus) => {

        this.setState({
            isSearchFocused : searchFocus
        })

        this.props.onClose()
    }

    onSearchOpen = (event) => {
        this.setState({
            isSearchFocused: true
        })
    }

    /**
    * On close
    */
    onSearchClose = (event) => {
        this.setState({
            isSearchFocused: false
        })
    }


    /**
     * Check  if node has children.
     * 
     * @param {*} node 
     * @returns 
     */
    hasChildren = (folder) => {
        return folder.children && folder.children.length > 0
    }
}

const mapStateToProps = (state) => {
    
    return {
        folderTree: state.folderTree,
        mstrAuthToken: state.mstrAuthToken,
        config: state.config
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onTreeStructureLoad: onTreeStructureLoad,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItems);
