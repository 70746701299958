import AbstractService from "./AbstractService";

class FavoritesService extends AbstractService {
    addFavorite = async (mstrLibraryUrl, reportName) => {
        const url = `${mstrLibraryUrl}/servlet/taskProc?taskId=addFavoriteTask&taskEnv=xhr&taskContentType=json&objectId=${encodeURIComponent(reportName)}&isFavorite=1`;

        return this.sendRequest(url, "GET", {
            'Content-Type': 'application/json',
            'accept':"application/json",
            'Access-Control-Allow-Origin':'*'
        }, {});
    }

    removeFavorite = async (mstrLibraryUrl, reportName) => {
        const url = `${mstrLibraryUrl}/servlet/taskProc?taskId=addFavoriteTask&taskEnv=xhr&taskContentType=json&objectId=${encodeURIComponent(reportName)}&isFavorite=0`;

        return this.sendRequest(url, "GET", {
            'Content-Type': 'application/json',
            'accept':"application/json",
            'Access-Control-Allow-Origin':'*'
        }, {});
    }

}

export const favoritesService = new FavoritesService();