import { deviceHelper } from "./DeviceHelper";

export const findItemByReportId = (state, objectId) => {
    let items = state.flatFolderTree;

    items = items.filter((item) => (item.did === objectId) || (item.powerBIId === objectId));

    return items.length ? items[0] : null;
}

export const getDefaultPage = (state, objectId) => {
    const item = findItemByReportId(state, objectId);

    if (item && item.longDescriptionProps) {
        const isMobile = deviceHelper.isMobile();
        const isTablet = deviceHelper.isTablet();

        if (isMobile) {
            return item.longDescriptionProps.mobileDefaultPage
        } else if (isTablet) {
            return item.longDescriptionProps.tabletDefaultPage
        } else {
            return item.longDescriptionProps.desktopDefaultPage
        }
    }

    return null;
}